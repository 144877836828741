import React, {useState} from 'react';
import FieldInput from "../components/FieldInput";
import {Link, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "../api/axios";
import useAuthService from "../hooks/useAuthService";
import {API_ORGANIZATION_INIT_REGISTRATION} from "../api/endpoints";

const OrganizationRegister = () => {
    const navigate = useNavigate()
    const authService = useAuthService()
    const [name, setName] = useState("");
    const [url, setUrl] = useState("");
    const [email, setEmail] = useState("");

    const getBaseUrl = () => {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    const organizationRegistrationHandler = async () => {
        const toast_id = toast.loading("Registration process please wait...")

        if (email.trim() === '' || name.trim() === '') {
            toast.update(toast_id, {render: "All fields required", type: "error", isLoading: false, autoClose: 3000});
        }

        try{
            const response = await axios.post(API_ORGANIZATION_INIT_REGISTRATION, {
                "name": name,
                "url": url,
                "email": email,
                "redirect_link_new": `${getBaseUrl()}/organization-register-confirmation`,
                "redirect_link_exists": `${getBaseUrl()}/confirm-registration`,
            })
            // authService.setVerificationEmail(response?.data?.email)
            toast.update(toast_id, {render: 'Please verify your email and confirm registration', type: "success", isLoading: false, autoClose: 5000})
            navigate('/login')
        }catch(err){
            console.log(err)
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Organization registration failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    const keyPressedHandler = (e) => {
        if (e.code === 'Enter') {
            organizationRegistrationHandler()
        }
    }

    return (
        <div className="flex h-screen justify-center items-center bg-no-repeat bg-cover">

            <div className='flex flex-col justify-center items-center w-[350px]'>
                <h1 className='text-4xl font-oswald text-guinea mb-4'>Register New Organization</h1>

                <div onKeyDown={keyPressedHandler} className='flex flex-col mt-4 justify-center items-center w-full gap-y-4'>
                    <FieldInput callback={setName} value={name} type='text' id='reg-org-name'
                                title='Organization Name'/>
                    <FieldInput callback={setEmail} value={email} type='email' id='reg-org-email'
                                title='Email'/>
                    <FieldInput callback={setUrl} value={url} type='text' id='reg-org-url'
                                title='Organization Url optional'/>
                </div>

                <button onClick={organizationRegistrationHandler}
                        className='text-water border border-water font-bold rounded-lg w-full py-2 mt-4 hover:border-water-light hover:text-water-light'>Register
                </button>

                <div className='text-guinea mt-4 w-full text-center'>Or login using your existing account</div>
                <div className='text-guinea mt-4 font-bold uppercase hover:text-guinea-light'>
                    <Link to='/login'>Log In</Link>
                </div>
            </div>

        </div>
    );
};

export default OrganizationRegister;