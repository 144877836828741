import React from 'react';
import {Link, useParams} from "react-router-dom";

const OrganizationMenu = (props) => {
    const {tab} = props
    const params = useParams();

    return (
        <div className='flex gap-4'>
            <Link to={`/organization/${params?.orgId}/dashboard`}
                  className={`w-full ${tab === 1 ? "text-guinea-light" : "text-guinea"} text-center border ${tab === 1 ? "border-guinea-light" : "border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Dashboard</Link>
            <Link to={`/organization/${params?.orgId}/members`}
                  className={`w-full ${tab === 2 ? "text-guinea-light" : "text-guinea"} text-center border ${tab === 2 ? "border-guinea-light" : "border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Members</Link>
            <Link to={`/organization/${params?.orgId}/settings`}
                  className={`w-full ${tab === 3 ? "text-guinea-light" : "text-guinea"} text-center border ${tab === 3 ? "border-guinea-light" : "border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Settings</Link>
            <Link to={`/organizations/`}
                  className={`w-full ${tab === 4 ? "text-guinea-light" : "text-guinea"} text-center border ${tab === 4 ? "border-guinea-light" : "border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>My Organizations</Link>

        </div>
    );
};

export default OrganizationMenu;