import React from 'react';
import {Link, useParams} from "react-router-dom";

const RepositoryMenu = (props) => {
    const {tab} = props
    const params = useParams();

    return (
        <div className='flex gap-4 z-10 bg-cinder'>
            <Link to={`/organization/${params?.orgId}/repository/upload-file`} className={`w-full ${tab === 1 ? "text-guinea-light": "text-guinea"} text-center border ${tab === 1 ? "border-guinea-light":"border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Uploads</Link>
            <Link to={`/organization/${params?.orgId}/repository/plugins`} className={`w-full ${tab === 2 ? "text-guinea-light": "text-guinea"} text-center border ${tab === 2 ? "border-guinea-light":"border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Plugins</Link>
            {/*<Link to={`/organization/${params?.orgId}/repository/vendors`} className={`w-full ${tab === 3 ? "text-guinea-light": "text-guinea"} text-center border ${tab === 3 ? "border-guinea-light":"border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Vendors</Link>*/}
            {/*<Link to={`/organization/${params?.orgId}/site/${params?.siteId}/backups`} className={`w-full ${tab === 4 ? "text-guinea-light": "text-guinea"} text-center border ${tab === 4 ? "border-guinea-light":"border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Backups</Link>*/}
        </div>
    );
};

export default RepositoryMenu;