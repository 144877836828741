import React, {useContext, useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from "react-router-dom";
import {AppContext} from "../components/AppContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {
    API_SITE_STATISTIC_DESKTOP,
    API_SITE_STATISTIC_MOBILE,
    API_SITE_STATISTIC_SYNC_DESKTOP,
    API_SITE_STATISTIC_SYNC_MOBILE,
    API_SITES,
    API_SITES_SET_ENV_STATUS,
    API_SITES_SSL_INFO,
    API_SITES_SYNC_SITE
} from "../api/endpoints";
import {toast} from "react-toastify";
import SiteMenu from "../components/SiteMenu";
import php from "../static/images/site/php.svg";
import wp from "../static/images/site/wp.svg";
import pluginCritical from "../static/images/site/plugins-critical.svg";
import pluginInstalled from "../static/images/site/plugin-installed.svg";
import pluginInactive from "../static/images/site/plugins-inactive.svg";
import pluginActive from "../static/images/site/plugins-active.svg";
import pluginsAvailable from "../static/images/site/plugins-available.svg";
import monitor from "../static/images/site/monitor.svg";
import ssl from "../static/images/site/ssl-issuer.svg";
import sslStart from "../static/images/site/ssl-start.svg";
import sslEnd from "../static/images/site/ssl-end.svg";
import desktop_performance from "../static/images/site/desktop-grafic.svg";
import desktop_access from "../static/images/site/desktop-usb.svg";
import desktop_score from "../static/images/site/desktop-monitor.svg";
import desktop_seo from "../static/images/site/desktop-search.svg";
import mobilePerformance from "../static/images/site/mobile-image.svg";
import mobileAccess from "../static/images/site/mobile-monitor.svg";
import mobileScore from "../static/images/site/mobile-speed.svg";
import mobileSeo from "../static/images/site/mobile-search.svg";
import syncInfo from "../static/images/site/sync-info.svg";
import connectSite from "../static/images/site/connect-site.svg";
import disconnectSite from "../static/images/site/disconnect-site.svg";
import editSite from "../static/images/site/edit-site.svg";
import deleteSite from "../static/images/site/delete-site.svg";
import {MdClose} from "react-icons/md";
import FieldInput from "../components/FieldInput";


const Site = () => {
    const params = useParams();
    const { setNavbarData } = useContext(AppContext);
    const axios = useAxiosPrivate();
    const [site, setSite] = useState(null);
    const navigate = useNavigate();

    const [siteSSLInfo, setSiteSSLInfo] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [siteName, setSiteName] = useState("");
    const [pluginsInfo, setPluginsInfo] = useState({});

    const [siteStatisticDesktop, setSiteStatisticDesktop] = useState(null);
    const [siteStatisticMobile, setSiteStatisticMobile] = useState(null);

    const [performanceDeskScore, setPerformanceDeskScore] = useState(null);
    const [accessibilityDeskScore, setAccessibilityDeskScore] = useState(null);
    const [bestPracticeDeskScore, setBestPracticeDeskScore] = useState(null);
    const [seoDeskScore, setSeoDeskScore] = useState(null);

    const [performanceMScore, setPerformanceMScore] = useState(null);
    const [accessibilityMScore, setAccessibilityMScore] = useState(null);
    const [bestPracticeMScore, setBestPracticeMScore] = useState(null);
    const [seoMScore, setSeoMScore] = useState(null);

    const [maintenanceMode, setMaintenanceMode] = useState(false);


    const fetchSiteData = async () => {
        try{
            const response = await axios(API_SITES(params?.orgId, params?.siteId))
            setSite(response?.data)
            setNavbarData({name: response?.data?.name})
            setSiteName(response?.data?.name)
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Fetch site failed")
            }
        }
    }

    const syncSiteData = async () => {
        const toast_id = toast.loading("Sync site please wait...")
        try{
            const response = await axios.post(API_SITES_SYNC_SITE(params?.orgId, params?.siteId))
            setSite(response?.data)
            setNavbarData({name: response?.data?.name})
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 2000})
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 2000})
            }else {
                toast.update(toast_id, {render: "Sync site failed", type: "error", isLoading: false, autoClose: 2000})
            }
        }
    }

    const fetchDesktopStatistic = () => {
        axios
            .get(API_SITE_STATISTIC_DESKTOP(params?.orgId, params?.siteId))
            .then((response) => {
                setSiteStatisticDesktop(response?.data)
            })
            .catch((err) => {
                const error_data = err?.response?.data?.errors?.[0] || null
                if (error_data) {
                    toast.error(error_data.detail)
                }else {
                    toast.error(`Fetching desktop statistics failed`)
                }
            })
    }

    const fetchMobileStatistic = () => {
        axios
            .get(API_SITE_STATISTIC_MOBILE(params?.orgId, params?.siteId))
            .then((response) => {
                setSiteStatisticMobile(response?.data)
            })
            .catch((err) => {
                const error_data = err?.response?.data?.errors?.[0] || null
                if (error_data) {
                    toast.error(error_data.detail)
                }else {
                    toast.error(`Fetching mobile statistics failed`)
                }
            })
    }

    const syncStatistic = async () => {
        setSiteStatisticDesktop(null)
        setSiteStatisticMobile(null)
        setPerformanceDeskScore(null)
        setAccessibilityDeskScore(null)
        setBestPracticeDeskScore(null)
        setSeoDeskScore(null)
        setPerformanceMScore(null)
        setAccessibilityMScore(null)
        setBestPracticeMScore(null)
        setSeoMScore(null)

        axios
            .get(API_SITE_STATISTIC_SYNC_DESKTOP(params?.orgId, params?.siteId))
            .then((response) => {
                setSiteStatisticDesktop(response?.data)
            })
            .catch((err) => {
                const error_data = err?.response?.data?.errors?.[0] || null
                if (error_data) {
                    toast.error(error_data.detail)
                }else {
                    toast.error(`Fetching desktop statistics failed`)
                }
            })

        axios
            .get(API_SITE_STATISTIC_SYNC_MOBILE(params?.orgId, params?.siteId))
            .then((response) => {
                setSiteStatisticMobile(response?.data)
            })
            .catch((err) => {
                const error_data = err?.response?.data?.errors?.[0] || null
                if (error_data) {
                    toast.error(error_data.detail)
                }else {
                    toast.error(`Fetching mobile statistics failed`)
                }
            })
    }

    const fetchSiteSSlInfo = async () => {
        try{
            const response = await axios(API_SITES_SSL_INFO(params?.orgId, params?.siteId))
            setSiteSSLInfo(response?.data)
        }catch(err){
            if (err?.response?.status === 404) {
                setSiteSSLInfo({
                    issuer: "No SSL",
                    valid_from: "No SSL",
                    valid_to: "No SSL",
                })
                return
            }
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Fetch site ssl certification failed")
            }
        }
    }

    useEffect(() => {
        fetchSiteData()
        fetchSiteSSlInfo()
        fetchDesktopStatistic()
        fetchMobileStatistic()
    }, []);

    useEffect(() => {
        const mode = site?.env_status
        if (mode?.includes('update') || mode?.includes('development')) {
            setMaintenanceMode(true);
        } else {
            setMaintenanceMode(false);
        }

        if (!site?.is_connected) {
            setMaintenanceMode(false)
        }

        const pluginsInfo = {
            total: site?.plugins?.length,
            available: site?.plugins?.filter(plugin => plugin.available_version).length,
            active: site?.plugins?.filter(plugin => plugin.active).length,
            disabled: site?.plugins?.filter(plugin => !plugin.active).length,
        }

        let count = 0
        site?.plugins?.forEach((plugin) => {
           const plugin_vulnerabilities = plugin?.vulnerabilities
            if (plugin_vulnerabilities) {
                for (let i = 0; i < plugin_vulnerabilities.length; i++) {
                    if (plugin_vulnerabilities[i].severity === 'Critical' || plugin_vulnerabilities[i].severity === 'High') {
                        count++
                        break
                    }
                }
            }
        })

        pluginsInfo['severity'] = count
        setPluginsInfo(pluginsInfo)
    }, [site])

    useEffect(() => {
        siteStatisticDesktop && setPerformanceDeskScore(siteStatisticDesktop.filter(stats => stats.type === "performance" && stats.device === 'desktop')[0].score)
        siteStatisticDesktop && setAccessibilityDeskScore(siteStatisticDesktop.filter(stats => stats.type === "accessibility" && stats.device === 'desktop')[0].score)
        siteStatisticDesktop && setBestPracticeDeskScore(siteStatisticDesktop.filter(stats => stats.type === "best-practices" && stats.device === 'desktop')[0].score)
        siteStatisticDesktop && setSeoDeskScore(siteStatisticDesktop.filter(stats => stats.type === "seo_score" && stats.device === 'desktop')[0].score)
    }, [siteStatisticDesktop])

    useEffect(() => {
        siteStatisticMobile && setPerformanceMScore(siteStatisticMobile.filter(stats => stats.type === "performance" && stats.device === 'mobile')[0].score)
        siteStatisticMobile && setAccessibilityMScore(siteStatisticMobile.filter(stats => stats.type === "accessibility" && stats.device === 'mobile')[0].score)
        siteStatisticMobile && setBestPracticeMScore(siteStatisticMobile.filter(stats => stats.type === "best-practices" && stats.device === 'mobile')[0].score)
        siteStatisticMobile && setSeoMScore(siteStatisticMobile.filter(stats => stats.type === "seo_score" && stats.device === 'mobile')[0].score)
    }, [siteStatisticMobile])

    const getScoreColor = (score) => {
        const score_num = parseInt(score)
        if (score_num >= 90){
            return "border-water text-water"
        }
        if (score_num >= 50) {
            return "border-guinea text-guinea"
        }
        return "border-spot text-spot"
    }

    const isDateLessThanOneMonth = (dateStr) => {
        if (dateStr === 'No SSL') {
            return true
        }
        const parsedDate = new Date(dateStr);
        const currentDate = new Date();
        const dateDifference = parsedDate - currentDate;
        const oneWeek = 29 * 24 * 60 * 60 * 1000;
        return dateDifference < oneWeek;
    }

    const disconnectHandler = async () => {
        const toast_id = toast.loading("Update site please wait...")
        try {
            await axios.patch(API_SITES(params?.orgId, params?.siteId), {
                is_connected: false,
            })

            setSite(site => ({
                ...site,
                is_connected: false,
            }))
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 3000})
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Update site failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    const editSiteHandler = async () => {
        const toast_id = toast.loading("Update site please wait...")
        try {
            await axios.patch(API_SITES(params?.orgId, params?.siteId), {
                name: siteName
            })
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 3000})
            setSite(prevState => ({
                ...prevState,
                name: siteName
            }))
            document.getElementById(`edit-site-name-${params?.siteId}`).close()
        } catch (err) {
            console.log(err)
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Update site failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    const deleteSiteHandler = async () => {
        const toast_id = toast.loading("Delete please wait...")
        try {
            await axios.delete(API_SITES(params?.orgId, params?.siteId))
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 3000})
            navigate(`/organization/${params?.orgId}/sites/`)
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Delete site failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
        document.getElementById(`site-delete-${params?.siteId}`).close()
    }

    const maintenanceModeHandler = async () => {
        setMaintenanceMode(!maintenanceMode)
        const toast_id = toast.loading("Change update mode please wait...")
        try{
            const response = await axios.post(API_SITES_SET_ENV_STATUS(params?.orgId, params?.siteId), {
                "env_status": maintenanceMode
            })
            setSite(response?.data)
            toast.update(toast_id, {render: 'Update mode changed', type: "success", isLoading: false, autoClose: 3000})
        }catch(err){
            setMaintenanceMode(!maintenanceMode)
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Change update mode failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    return (
        <div className='pb-8'>
            <SiteMenu tab={1}/>
            <div className='cursor-pointer flex items-center gap-x-10 justify-end mt-20'>
                <div>
                    <label className="inline-flex items-center cursor-pointer ml-10">
                        <input type="checkbox" onChange={maintenanceModeHandler} value={`${maintenanceMode}`} checked={maintenanceMode} className="sr-only peer"/>
                        <div className="relative w-9 h-5 bg-gray-400 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-gray-100 after:border-gray-400 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-spot"></div>
                        <span className={`ms-2 text-sm font-medium font-oswald text-${maintenanceMode ? "spot" : "guinea"}`}>Activate Update Mode</span>
                    </label>
                </div>
                <div>
                    <label className="inline-flex items-center cursor-pointer ml-10">
                        <input type="checkbox" onChange={() => setIsAdmin(!isAdmin)} value={`${isAdmin}`} checked={isAdmin} className="sr-only peer"/>
                        <div className="relative w-9 h-5 bg-gray-400 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-gray-100 after:border-gray-400 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-spot"></div>
                        <span className={`ms-2 text-sm font-medium font-oswald text-${isAdmin ? "spot" : "guinea"}`}>Admin Mode</span>
                    </label>
                </div>
                <div onClick={() => {
                    syncSiteData()
                    syncStatistic()
                }} data-tip='Sync' className='tooltip tooltip-top h-10 w-10 transition duration-300 hover:scale-105'>
                    <img src={syncInfo} alt="sync"/>
                </div>
            </div>

            <div className='flex flex-col text-4xl font-oswald font-extralight text-guinea'>
                <div className='flex flex-col items-center'>
                    <div className={`text-${site?.is_connected ? "guinea":"spot"} ${maintenanceMode ? "animate-pulse text-water" : ""}`}>{site?.name}</div>
                    <div className={`text-sm text-${site?.is_connected ? "water":"spot"} hover:text-${site?.is_connected ? "water-light":"spot-light"} mt-2 ${maintenanceMode ? "animate-pulse" : ""}`}><Link to={site?.url} target='_blank'>{site?.url}</Link></div>
                </div>

                <div className='flex flex-wrap gap-8 mt-10 justify-center'>
                    <div className='flex flex-col flex-wrap justify-center items-center border border-gray-600 rounded-lg p-8 hover:border-guinea-light'>
                        <div className={`uppercase`}>Site Info</div>
                        <div className='flex justify-center flex-wrap mt-8 gap-8'>
                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={php} alt="php"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105 '/>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>PHP
                                    Version {site?.php_version}</div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center relative group'>
                                <img src={wp} alt="php"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Wordpress
                                    Version {site?.wordpress_version}</div>
                                <div
                                    className='absolute left-1/2 transform -translate-x-1/2 -bottom-16 text-guinea text-sm bg-vulcan w-52 font-istok rounded-lg p-4 hidden justify-center group-hover:flex'>
                                    <div className='w-10 h-10 absolute bg-vulcan rotate-45 top-0 -z-10'></div>
                                    <span
                                        className='text-center'>Wordpress update available: {site?.wordpress_available === ' ' ? "Up to date" : `*${site?.wordpress_available}`}</span>
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={monitor} alt="php"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={`text-overview lg:text-overview-lg xl:text-overview-xl ${site?.type === 'live' ? "text-water" : "text-guinea"} group-hover:text-guinea-light capitalize`}>{site?.type}</div>
                            </div>
                        </div>
                    </div>

                    <div
                        className='flex flex-col flex-wrap justify-center items-center border border-gray-600 rounded-lg p-8 hover:border-guinea-light'>
                        <div className='uppercase'>SSL Info</div>
                        <div className='flex justify-center flex-wrap mt-8 gap-8'>
                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={ssl} alt="ssl"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>SSL
                                    Issuer {siteSSLInfo?.issuer ??
                                        <span className="loading loading-spinner loading-xs"></span>}</div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center relative group'>
                                <img src={sslStart} alt="ssl-start"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Valid
                                    From: {siteSSLInfo?.valid_from ??
                                        <span className="loading loading-spinner loading-xs"></span>}</div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={sslEnd} alt="ssl-end"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={`text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light capitalize`}>Expire: <span
                                    className={`${isDateLessThanOneMonth(siteSSLInfo?.valid_to) ? "text-spot" : "text-water"}`}>{siteSSLInfo?.valid_to ??
                                    <span className="loading loading-spinner loading-xs"></span>}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        className='flex flex-col flex-wrap justify-center items-center border border-gray-600 rounded-lg p-8 hover:border-guinea-light'>
                        <div className='uppercase'>Plugins Info</div>

                        <div className='flex justify-center flex-wrap mt-8 gap-8'>
                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={pluginInstalled} alt="plugin-installed"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Plugins
                                    Installed: <span className='text-water'>{pluginsInfo.total}</span>
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={pluginsAvailable} alt="active-plugins"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Plugins
                                    Available
                                    For Updates: <span className='text-water'>{pluginsInfo.available}</span>
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={pluginActive} alt="active-plugins"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Active
                                    plugins: <span className='text-water'>{pluginsInfo.active}</span></div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={pluginInactive} alt="active-plugins"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Disabled
                                    Plugins: <span className='text-water'>{pluginsInfo.disabled}</span>
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={pluginCritical} alt="active-plugins"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Critical
                                    Updates: <span
                                        className={`text-${pluginsInfo.severity === 0 ? "water" : "spot"}`}>{pluginsInfo.severity}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className='flex flex-wrap gap-8 mt-8 justify-center'>
                    <div className='flex flex-col relative flex-wrap justify-center items-center border border-gray-600 rounded-lg p-8 hover:border-guinea-light'>
                        <div className='uppercase text-center'>Desktop Performance</div>
                        <div className='flex justify-center flex-wrap mt-8 gap-8'>
                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={desktop_performance} alt="desktop"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={`text-sm text-center rounded-lg py-1 w-12 border ${getScoreColor(performanceDeskScore)}`}>{performanceDeskScore ??
                                    <span className="loading loading-spinner loading-xs"></span>}</div>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Desktop
                                    Performance
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={desktop_access} alt="desktop"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={`text-sm text-center rounded-lg py-1 w-12 border ${getScoreColor(accessibilityDeskScore)}`}>{accessibilityDeskScore ??
                                    <span className="loading loading-spinner loading-xs"></span>}</div>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Desktop
                                    Accessibility
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={desktop_score} alt="desktop"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={`text-sm text-center rounded-lg py-1 w-12 border ${getScoreColor(bestPracticeDeskScore)}`}>{bestPracticeDeskScore ??
                                    <span className="loading loading-spinner loading-xs"></span>}</div>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Best
                                    Practice
                                    Score
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={desktop_seo} alt="desktop"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={` text-sm text-center rounded-lg py-1 w-12 border ${getScoreColor(seoDeskScore)}`}>{seoDeskScore ??
                                    <span className="loading loading-spinner loading-xs"></span>}</div>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Seo
                                    Score
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='flex flex-col relative flex-wrap justify-center items-center border border-gray-600 rounded-lg p-8 hover:border-guinea-light'>
                        <div className='uppercase text-center'>Mobile Performance</div>
                        <div className='flex justify-center flex-wrap mt-8 gap-8'>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={mobilePerformance} alt="mobile"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl -mr-4 transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={`text-sm text-center rounded-lg py-1 w-12 border ${getScoreColor(performanceMScore)}`}>{performanceMScore ??
                                    <span className="loading loading-spinner loading-xs"></span>}</div>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Mobile Performance
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={mobileAccess} alt="mobile"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl -mr-4 transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={`text-sm text-center rounded-lg py-1 w-12 border ${getScoreColor(accessibilityMScore)}`}>{accessibilityMScore ??
                                    <span className="loading loading-spinner loading-xs"></span>}</div>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Mobile Accessibility
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={mobileScore} alt="mobile"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl -mr-4 transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={`text-sm text-center rounded-lg py-1 w-12 border ${getScoreColor(bestPracticeMScore)}`}>{bestPracticeMScore ??
                                    <span className="loading loading-spinner loading-xs"></span>}</div>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Best Practice Score
                                </div>
                            </div>

                            <div className='flex flex-col justify-center cursor-pointer items-center group'>
                                <img src={mobileSeo} alt="mobile"
                                     className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl relative left-1 transition duration-200 group-hover:scale-105'/>
                                <div
                                    className={`text-sm text-center rounded-lg py-1 w-12 border ${getScoreColor(seoMScore)}`}>{seoMScore ??
                                    <span className="loading loading-spinner loading-xs"></span>}</div>
                                <div
                                    className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Seo Score
                                </div>
                            </div>

                        </div>

                    </div>
                    {isAdmin &&
                        <div
                            className='flex flex-col flex-wrap justify-center items-center border border-spot rounded-lg p-8 hover:border-spot-light'>
                            <div className='uppercase text-center'>Danger Area</div>
                            <div className='flex justify-center flex-wrap mt-8 gap-8'>

                                <div onClick={syncSiteData} className='flex flex-col justify-center cursor-pointer items-center group'>
                                    <img src={connectSite} alt="connect"
                                         className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                    <div
                                        className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Connect
                                        Site
                                    </div>
                                </div>

                                <div onClick={disconnectHandler} className='flex flex-col justify-center cursor-pointer items-center group'>
                                    <img src={disconnectSite} alt="connect"
                                         className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                    <div
                                        className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Disconnect
                                        Site
                                    </div>
                                </div>

                                <div onClick={() => document.getElementById(`edit-site-name-${params?.siteId}`).showModal()} className='flex flex-col justify-center cursor-pointer items-center group'>
                                    <img src={editSite} alt="connect"
                                         className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                    <div
                                        className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Edit
                                        Name
                                    </div>
                                </div>

                                <div onClick={() => document.getElementById(`site-delete-${params?.siteId}`).showModal()}
                                     className='flex flex-col justify-center cursor-pointer items-center group'>
                                    <img src={deleteSite} alt="connect"
                                         className='h-image-icon w-image-icon md:h-image-md md:w-image-md lg:h-image-lg lg:w-image-lg xl:h-image-xl xl:w-image-xl transition duration-200 group-hover:scale-105'/>
                                    <div
                                        className='text-overview lg:text-overview-lg xl:text-overview-xl text-guinea group-hover:text-guinea-light'>Delete
                                        Site
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>

            <dialog id={`site-delete-${params?.siteId}`} className="modal">
                <div className="modal-box bg-cinder">

                    <div>
                        <h3 className="font-light text-lg text-guinea text-center font-oswald">Delete {site?.name} ?</h3>
                    </div>
                    <div className='flex justify-around items-center mt-4'>
                        <div>
                            <button onClick={deleteSiteHandler}
                                    className='text-spot hover:text-redom border border-spot hover:border-redom rounded-lg px-8 py-1'>Delete
                            </button>
                        </div>
                        <div className="relative modal-action -top-3">
                            <form method="dialog">
                                <button
                                    className='text-water hover:text-water-light border border-water hover:border-water-light rounded-lg px-8 py-1'>Cancel
                                </button>
                            </form>
                        </div>
                    </div>

                </div>
            </dialog>

            <dialog id={`edit-site-name-${params?.siteId}`} className="modal">
                <div className="modal-box bg-cinder">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="text-sm">
                                <button className=""><MdClose
                                    className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-light text-lg text-guinea font-oswald uppercase">Edit Name - {site?.name}</h3>
                    </div>

                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setSiteName} value={siteName} type='text' id='site-name'
                                    title='Site Name'/>
                        <button onClick={editSiteHandler}
                                className='text-water border border-water font-bold rounded-lg w-full py-2 mt-4 hover:border-water-light hover:text-water-light'>Save Changes
                        </button>
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default Site;