import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import Report from "../components/Report";
import {toast} from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {MdClose} from "react-icons/md";
import createReport from "../static/images/sites/add-new-report.svg"
import FieldInput from "../components/FieldInput";
import SiteMenu from "../components/SiteMenu";
import {AppContext} from "../components/AppContext";
import {API_SITE_REPORTS} from "../api/endpoints";

const SiteReports = () => {
    const params = useParams()
    const { setNavbarData } = useContext(AppContext);
    const [reports, setReports] = useState([])
    const axios = useAxiosPrivate()
    const [reportName, setReportName] = useState("")
    const [reportDocumentLink, setReportDocumentLink] = useState("")
    const [reportType, setReportType] = useState("")

    const fetchReportsData = async () => {
        try{
            const response = await axios.get(API_SITE_REPORTS(params?.orgId, params?.siteId))
            setReports(response?.data)
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Fetch reports failed")
            }
        }
    }

    useEffect(() => {
        fetchReportsData()
    }, []);

    const creteReportHandler = async () => {
        if (!reportDocumentLink) {
            toast.info("Please enter a valid document id")
            return
        }

        if (!reportType) {
            toast.info("Please select report type")
            return
        }

        if (!reportName) {
            toast.info("Please enter a valid report name")
            return
        }

        const toast_id = toast.loading("Send request please wait...")
        try{
            const response = await axios.post(API_SITE_REPORTS(params?.orgId, params?.siteId), {
                // site: params?.siteId,
                report_name: reportName,
                link: reportDocumentLink,
                report_type: reportType,
            })
            setReportName("")
            setReportDocumentLink("")
            setReportType("")
            // fetchReportsData()
            toast.update(toast_id, {render: "Report creation success", type: "success", isLoading: false, autoClose: 3000})
            setReports(reports => [
                ...reports,
                response?.data
            ])
            document.getElementById("report-create").close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Report creation failed', type: "error", isLoading: false, autoClose: 3000})
                document.getElementById("report-create").close()
            }
        }
    }

    const reportDeleteHandler = async (report_id, modal_id) => {
        const toast_id = toast.loading("Report Delete Please wait...")
        try{
            await axios.delete(API_SITE_REPORTS(params?.orgId, params?.siteId, report_id))
            setReports(reports.filter(report => report.id !== report_id))
            toast.update(toast_id, {render: "Report deleted successfully.", type: "success", isLoading: false, autoClose: 3000})
        }catch (err) {
            const error_data = err?.response?.data?.errors[0]
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Deletion failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
        document.getElementById(modal_id).close()
    }

    return (
        <section>
            <SiteMenu id={params?.id} tab={3}/>
            <div className='flex flex-col mt-24'>
                <div className='flex gap-4 flex-wrap'>
                    {reports && reports.map(report => (
                        <Report key={report.id}
                                id={report.id}
                                name={report.report_name}
                                type={report.report_type}
                                link={report.link}
                                deleteCallback={reportDeleteHandler}
                        />
                    ))}
                    <div onClick={() => document.getElementById("report-create").showModal()}
                         className='flex justify-center h-[250px] items-center gap-4 border border-gray-600 rounded-lg cursor-pointer group tooltip'
                         data-tip="Creat New Report">
                        <img src={createReport} alt="report"
                             className='h-40 mt-4 transition duration-200 group-hover:scale-105'/>
                    </div>
                </div>

                <dialog id="report-create" className="modal">
                    <div className="modal-box bg-cinder">
                        <div className="modal-action absolute right-4 -top-2">
                            <form method="dialog">
                                <div className="text-sm">
                                    <button className=""><MdClose
                                        className='text-guinea text-2xl hover:text-guinea-light'/>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <h3 className="font-light text-lg text-guinea font-oswald uppercase">Create New Report</h3>
                        </div>

                        <div className='flex flex-col gap-y-4 mt-8'>
                            <FieldInput callback={setReportDocumentLink} value={reportDocumentLink} type='text'
                                        id='report-doc-id' title='Report document link'/>
                            <div className='flex text-guinea'>
                                <select onChange={(e) => setReportType(e.target.value)} defaultValue='default'
                                        className="select text-guinea w-full bg-transparent border border-gray-500 outline-none focus:outline-none focus:ring-0 focus:border-guinea">
                                    <option className='text-guinea' disabled value='default'>Select report type</option>
                                    <option className='text-guinea' value='check'>CHECK</option>
                                    <option className='text-guinea' value='update'>UPDATE</option>
                                    <option className='text-guinea' value='test'>CUSTOM</option>
                                </select>
                            </div>
                            <FieldInput callback={setReportName} value={reportName} type='text' id='report-name'
                                        title='Report name'/>

                            <button onClick={creteReportHandler}
                                    className='text-water border border-water font-light rounded-lg w-full py-2 mt-4 hover:border-water-light hover:text-water-light'>Create
                                New Report
                            </button>
                        </div>
                    </div>
                </dialog>
            </div>
        </section>
    );
};

export default SiteReports;