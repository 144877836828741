import React from 'react';
import RepositoryMenu from "../components/RepositoryMenu";

const RepositoryVendors = () => {
    return (
        <div>
            <RepositoryMenu />
            <div></div>
        </div>
    );
};

export default RepositoryVendors;