import React, {useContext, useEffect} from 'react';
import NavBar from "../components/NavBar";
import {AppContext} from "../components/AppContext";
import OrganizationCard from "../components/OrganizationCard";
import OrganizationsSkeleton from "../components/skeletons/OrganizationsSkeleton";

const Organizations = () => {
    const { setNavbarData, userData } = useContext(AppContext);

    useEffect(() => {
        setNavbarData({name:'Organizations'})
    }, []);

    return (
        <div>
            <NavBar/>
            <div className='mt-32 ml-8 flex gap-8'>
                {userData && userData.organizations && userData.organizations.length > 0 ? (
                    userData.organizations.map((org) => (
                    <OrganizationCard key={org.organization.id} org={org} />
                ))): <OrganizationsSkeleton count={4}/>}
            </div>
        </div>
    );
};

export default Organizations;