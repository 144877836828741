export const API_AUTH_TOKEN = '/api/auth/token';
export const API_AUTH_REFRESH_TOKEN = '/api/auth/token/refresh';
export const API_USERS = '/api/users/';
export const API_USER_PROFILES_SELF = '/api/users/me/';
export const API_USER_EMAIL_VERIFICATION = '/api/users/confirm-send/';
export const API_USER_EMAIL_CONFIRM = '/api/users/confirm-verify/';
export const API_USER_RESET_PASSWORD_REQUEST = '/api/users/reset-password/';
export const API_USER_RESET_PASSWORD_CONFIRM = '/api/users/confirm-password/';

// Organizations
export const API_ORGANIZATION = (orgId = '') => `/api/organizations/${orgId}${orgId ? '/' : ''}`;
export const API_ORGANIZATION_INIT_REGISTRATION = '/api/organizations/init-registration/';  // POST
export const API_ORGANIZATION_MEMBERS = (orgId) => `/api/organizations/${orgId}/members/`;
export const API_ORGANIZATION_MEMBERS_INVITATION = (orgId) => `/api/organizations/${orgId}/create-invitation/`;
export const API_ORGANIZATION_DELETE_MEMBER = (orgId) => `/api/organizations/${orgId}/delete-member/`;
export const API_ORGANIZATION_EDIT_MEMBER = (orgId) => `/api/organizations/${orgId}/edit-member/`;
export const API_ORGANIZATION_INFO = (orgId) => `/api/organizations/${orgId}/organization-info/`;

// Sites
export const API_SITES = (org_id, site_id = '') => `/api/organizations/${org_id}/sites/${site_id}${site_id ? '/' : ''}`;
export const API_SITES_SYNC_SITE = (org_id, site_id) => `/api/organizations/${org_id}/sites/${site_id}/sync-site/`;
export const API_SITES_RECONNECT = (org_id, site_id) => `/api/organizations/${org_id}/sites/${site_id}/site-reconnect/`;
export const API_SITES_SET_ENV_STATUS = (org_id, site_id) => `/api/organizations/${org_id}/sites/${site_id}/set-status/`;
export const API_SITES_VULNERABLE = (org_id) => `/api/organizations/${org_id}/sites?vulnerable=true`;
// Site Plugins
export const API_SITE_PLUGINS = (org_id, site_id, plugin_id= '') => `/api/organizations/${org_id}/sites/${site_id}/plugins/${plugin_id}${plugin_id ? '/' : ''}`

// Site Reports
export const API_SITE_REPORTS = (org_id, site_id, report_id= '') => `/api/organizations/${org_id}/sites/${site_id}/reports/${report_id}${report_id ? '/' : ''}`
export const API_SITE_REPORTS_GENERATE = (org_id, site_id, report_id= '') => `/api/organizations/${org_id}/sites/${site_id}/reports/${report_id}/generate/`

// Site Backups
export const API_SITE_BACKUPS = (org_id, site_id, backup_id= '') => `/api/organizations/${org_id}/sites/${site_id}/backups/${backup_id}${backup_id ? '/' : ''}`
export const API_SITE_BACKUPS_DB = (org_id, site_id, backup_id) => `/api/organizations/${org_id}/sites/${site_id}/backups/${backup_id}/backup-db/`
export const API_SITE_BACKUPS_WP = (org_id, site_id, backup_id) => `/api/organizations/${org_id}/sites/${site_id}/backups/${backup_id}/backup-wp/`
export const API_SITE_BACKUPS_ALL = (org_id, site_id, backup_id) => `/api/organizations/${org_id}/sites/${site_id}/backups/${backup_id}/backup-all/`
export const API_SITE_BACKUPS_SCHEDULE_INFO = (org_id, site_id, backup_id) => `/api/organizations/${org_id}/sites/${site_id}/backups/${backup_id}/schedule-info/`

// Site Statistic
export const API_SITE_STATISTIC_DESKTOP = (org_id, site_id) => `/api/organizations/${org_id}/sites/${site_id}/statistics/desktop/`
export const API_SITE_STATISTIC_MOBILE = (org_id, site_id) => `/api/organizations/${org_id}/sites/${site_id}/statistics/mobile/`
export const API_SITE_STATISTIC_SYNC_DESKTOP = (org_id, site_id) => `/api/organizations/${org_id}/sites/${site_id}/statistics/sync-desktop/`
export const API_SITE_STATISTIC_SYNC_MOBILE = (org_id, site_id) => `/api/organizations/${org_id}/sites/${site_id}/statistics/sync-mobile/`

// SSL Info
export const API_SITES_SSL_INFO = (org_id, site_id) => `/api/organizations/${org_id}/sites/${site_id}/ssl-info/`

// WordPress Plugin Repository
export const API_WP_REPOSITORY = (pluginId = '') => `/api/repository/${pluginId}${pluginId ? '/' : ''}`
export const API_WP_REPOSITORY_MANUAL_UPLOAD = '/api/repository/manual-upload/'
export const API_WP_REPOSITORY_DOWNLOAD_PLUGIN = (slug, version) => `/api/repository/packages-download?slug=${slug}&version=${version}`

// WordPress Plugin GitHub Repository
export const API_WP_REPOSITORY_GITHUB = (githubId = '') => `/api/github-repository/${githubId}${githubId ? '/' : ''}`

// WordPress Plugin Custom Vendor
export const API_WP_REPOSITORY_CUSTOM_VENDOR = (vendorId = '') => `/api/custom-vendor/${vendorId}${vendorId ? '/' : ''}`