import React, {useState} from 'react';
import {FaRegEye, FaRegEyeSlash} from "react-icons/fa";


const FieldInput = (props) => {
    const {callback = null, value, type, id, title, isDisabled = false} = props;
    const [isHidden, setIsHidden] = useState(true);

    return (
        <>
            {type !== 'password' ?
                <div className="relative w-full">
                    <input
                        onChange={(e) => callback && callback(e.target.value)}
                        value={value}
                        type={type}
                        id={id}
                        disabled={isDisabled}
                        className={`block px-4 pb-2 pt-3 w-full text-sm ${isDisabled ? "text-guinea":"text-ultimate"} bg-transparent rounded-lg border-1 ${isDisabled ? "border-gray-700":"border-gray-500"} appearance-none focus:outline-none focus:ring-0 focus:border-guinea peer`}
                        placeholder=" "/>
                    <label htmlFor={id}
                           className={`absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-cinder px-2 peer-focus:px-1.5 peer-focus:text-guinea peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1`}>{title}</label>
                </div>
                :
                <div className="relative w-full">
                    <input
                        onChange={(e) => callback && callback(e.target.value)}
                        value={value}
                        type={isHidden ? 'password' : 'text'}
                        id={id}
                        className="block px-4 pb-2 pt-3 w-full text-sm text-ultimate bg-transparent rounded-lg border-1 border-gray-500 appearance-none focus:outline-none focus:ring-0 focus:border-guinea peer"
                        placeholder=" "/>
                    <label htmlFor={id}
                           className="absolute text-sm text-gray-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-cinder px-2 peer-focus:px-1.5 peer-focus:text-guinea peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{title}</label>
                    {isHidden ?
                        <FaRegEyeSlash className='text-2xl absolute right-4 top-2'
                                       onClick={() => setIsHidden(!isHidden)}/> :
                        <FaRegEye className='text-2xl absolute right-4 top-2'
                                  onClick={() => setIsHidden(!isHidden)}/>}
                </div>
            }
        </>
    );
};

export default FieldInput;