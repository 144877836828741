import React from 'react';
import organization from "../static/images/organization/organization.svg"
import {useNavigate} from "react-router-dom";
const OrganizationCard = ({org}) => {
    const navigate = useNavigate();

    return (
        <div onClick={() => navigate(`/organization/${org.organization.id}`)} className={`w-[340px] flex flex-col text-center items-center bg-vulcan shadow-lg rounded-lg overflow-hidden transition cursor-pointer duration-200 border border-gray-600 hover:border-guinea`}>
            <div className='text-guinea bg-eclipse py-6 px-2 w-full text-xl font-oswald font-light'>{org.organization.name}</div>
            <div className='mt-6 mb-6'><img src={org.organization.image ?? organization } alt="organization" className='w-[220px] h-[220px]'/></div>
            <div className='bg-eclipse w-full py-6 px-2'>
                <div className='text-ultimate'>Role</div>
                <div className='text-guinea-light uppercase'>{org.role}</div>
            </div>
        </div>
    );
};

export default OrganizationCard;