import React, {useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "../api/axios";
import {API_USERS} from "../api/endpoints";

const ConfirmInvitation = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const requestSentRef = useRef(false);

    const invitationConfirmHandler = async () => {
        if (requestSentRef.current) return;

        requestSentRef.current = true;
        setIsSubmitting(true);

        const code = params.get('code')
        try{
            const response = await axios.post(API_USERS, {
                code,
            })
            toast.success('Registration complete, use your existing account to login')
            navigate('/login')
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.error(error_data.detail)
            }else {
                toast.error('Confirm registration failed')
            }
        }finally {
            setIsSubmitting(false);
        }
    }

    useEffect(() => {
        invitationConfirmHandler()
    }, [])

    return (
        <></>
    );
};

export default ConfirmInvitation;