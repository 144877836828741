import React, {useState} from 'react';
import FieldInput from "../components/FieldInput";
import {toast} from "react-toastify";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "../api/axios";
import {API_USER_RESET_PASSWORD_CONFIRM} from "../api/endpoints";

const ResetPassword = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const resetPasswordHandler = async () => {
        if (!newPassword || !confirmPassword) {
            toast.info("All fields are required!");
            return;
        }

        if (newPassword.trim() !== confirmPassword.trim()) {
            toast.info("Passwords don't match")
            return
        }

        const code = params.get('code')
        const toast_id = toast.loading("Update password please wait...")

        try{
            const response = await axios.post(API_USER_RESET_PASSWORD_CONFIRM, {
                password: newPassword,
                code,
            })
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 3000})
            navigate('/login')
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Update password failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    return (
        <div>
            <div className="flex w-full text-center h-screen justify-center items-center">
                <div className='flex flex-col gap-y-4 w-[300px]'>
                    <div className='text-guinea uppercase'>Reset your password</div>
                    <FieldInput callback={setNewPassword} value={newPassword} type='password' id='new-password' title='New password'/>
                    <FieldInput callback={setConfirmPassword} value={confirmPassword} type='password' id='new-password-confirm' title='Confirm password'/>
                    <button onClick={resetPasswordHandler} className="border border-water text-water hover:text-water-light mt-4 rounded-md py-2 w-full hover:border-water-light font-istok">Save Changes</button>
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;