const useAuthService = () => {
    const getAccessToken = () => localStorage.getItem('accessToken');
    const setAccessToken = (accessToken) => localStorage.setItem('accessToken', accessToken);
    const removeAccessToken = () => localStorage.removeItem('accessToken');
    const getRefreshToken = () => localStorage.getItem('refreshToken');
    const setRefreshToken = (refreshToken) => localStorage.setItem('refreshToken', refreshToken);
    const removeRefreshToken = () => localStorage.removeItem('refreshToken');
    const setVerificationEmail = (verificationEmail) => localStorage.setItem('verificationEmail', verificationEmail);
    const removeVerificationEmail = () => localStorage.removeItem('verificationEmail');
    const getVerificationEmail = () => localStorage.getItem('verificationEmail');
    const setDefaultOrganization = (organization_id) => localStorage.setItem('defaultOrganization', organization_id);
    const getDefaultOrganization = () => localStorage.getItem('defaultOrganization');
    const removeDefaultOrganization = () => localStorage.removeItem('defaultOrganization');


    const logout = () => {
        removeRefreshToken()
        removeAccessToken()
        removeVerificationEmail()
        removeDefaultOrganization()
    }
    const isAuthenticated = () => {
        const accessToken = getAccessToken();
        return accessToken !== null && accessToken !== undefined;
    };

    return {
        getAccessToken,
        setAccessToken,
        removeAccessToken,
        getRefreshToken,
        setRefreshToken,
        removeRefreshToken,
        isAuthenticated,
        setVerificationEmail,
        removeVerificationEmail,
        getVerificationEmail,
        setDefaultOrganization,
        getDefaultOrganization,
        removeDefaultOrganization,
        logout,
    };
};

export default useAuthService;