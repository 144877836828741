import React from 'react';
import {Link, useParams} from "react-router-dom";

const SiteMenu = (props) => {
    const {tab} = props
    const params = useParams();

    return (
        <div className='flex gap-4 fixed top-[60px] w-[calc(100%-102px)] bg-cinder z-50 pr-8 py-8'>
            <Link to={`/organization/${params?.orgId}/site/${params?.siteId}`} className={`w-full ${tab === 1 ? "text-guinea-light": "text-guinea"} text-center border ${tab === 1 ? "border-guinea-light":"border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Overview</Link>
            <Link to={`/organization/${params?.orgId}/site/${params?.siteId}/plugins`} className={`w-full ${tab === 2 ? "text-guinea-light": "text-guinea"} text-center border ${tab === 2 ? "border-guinea-light":"border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Plugins</Link>
            <Link to={`/organization/${params?.orgId}/site/${params?.siteId}/reports`} className={`w-full ${tab === 3 ? "text-guinea-light": "text-guinea"} text-center border ${tab === 3 ? "border-guinea-light":"border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Reports</Link>
            <Link to={`/organization/${params?.orgId}/site/${params?.siteId}/backups`} className={`w-full ${tab === 4 ? "text-guinea-light": "text-guinea"} text-center border ${tab === 4 ? "border-guinea-light":"border-gray-600"} uppercase py-2 hover:border-guinea hover:text-guinea-light rounded-lg`}>Backups</Link>
        </div>
    );
};

export default SiteMenu;