import React, {useState} from 'react';
import backup from "../static/images/sites/backup.svg";
import {MdClose} from "react-icons/md";
import reportOpen from "../static/images/sites/open-report.svg";
import backupEdit from "../static/images/backup/backup-edit.svg";
import reportDelete from "../static/images/sites/report-delete.svg";
import backupNow from "../static/images/backup/backup-now.svg";
import FieldInput from "./FieldInput";
import {toast} from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {API_SITE_BACKUPS, API_SITE_BACKUPS_ALL} from "../api/endpoints";
import {useParams} from "react-router-dom";

const Backup = (props) => {
    const {name, folder_url, id, deleteCallback, execution_url} = props
    const params = useParams();
    const [backupFolderUrl, setBackupFolderUrl] = useState(folder_url)
    const [backupName, setBackupName] = useState(name)
    const [backupExecutionLink, setBackupExecutionLink] = useState(execution_url)
    const axios = useAxiosPrivate();

    const updateBackupHandler = async () => {
        const toast_id = toast.loading("Update backup please wait...")
        try {
            const response = await axios.patch(API_SITE_BACKUPS(params?.orgId, params?.siteId, id), {
                folder_url: backupFolderUrl,
                execution_url: backupExecutionLink,
            })
            setBackupFolderUrl(response?.data.folder_url)
            setBackupExecutionLink(response?.data.execution_url)
            setBackupName(response?.data.backup_name)
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 3000})
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Update backup failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
        document.getElementById(`backup-${id}-update`).close()
    }

    const backupStartHandler = async () =>{
        const toast_id = toast.loading("Backup in process please wait...")
        try {
            await axios.post(API_SITE_BACKUPS_ALL(params?.orgId, params?.siteId, id))
            toast.update(toast_id, {render: 'Backup started', type: "success", isLoading: false, autoClose: 3000})
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Start backup failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    return (
        <div data-tip={backupName} className='shadow-vulcan shadow-lg tooltip'>
            <div onClick={() => document.getElementById(`backup-${id}`).showModal()}
                 className='flex flex-col gap-4 border border-gray-600 rounded-lg w-40 cursor-pointer group'>
                <img src={backup}
                     alt="backup"
                     className='h-40 mt-4 transition duration-200 group-hover:scale-105'/>
                <div className=' text-guinea font-oswald hover:text-guinea-light flex justify-center items-center bg-vulcan py-4 px-2 text-center h-full rounded-b-lg'><span className='line-clamp-1'>{backupName}</span></div>
            </div>

            <dialog id={`backup-${id}`} className="modal">
                <div className="modal-box bg-cinder">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="text-sm">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-light text-lg text-guinea font-oswald uppercase text-center">{backupName}</h3>
                    </div>

                    <div className="flex justify-center gap-x-8 mt-8">
                        <div onClick={backupStartHandler}
                             className="flex flex-col text-guinea items-center cursor-pointer group gap-y-2 text-sm">
                            <img src={backupNow} alt="generate"
                                 className='w-10 h-10 group-hover:scale-105 transition duration-200'/>
                            <span className='group-hover:text-guinea-light'>Backup Now</span>
                        </div>
                        <div onClick={() => window.open(folder_url, '_blank')}
                             className="flex flex-col text-guinea items-center cursor-pointer group gap-y-2 text-sm">
                            <img src={reportOpen} alt="open"
                                 className='w-10 h-10 group-hover:scale-105 transition duration-200'/>
                            <span className='group-hover:text-guinea-light'>Open Backup</span>
                        </div>
                        <div onClick={() => document.getElementById(`backup-${id}-update`).showModal()}
                             className="flex flex-col text-guinea items-center cursor-pointer group gap-y-2 text-sm">
                            <img src={backupEdit} alt="update"
                                 className='w-10 h-10 group-hover:scale-105 transition duration-200'/>
                            <span className='group-hover:text-guinea-light'>Edit Backup</span>
                        </div>
                        <div onClick={() => document.getElementById(`backup-${id}-delete`).showModal()}
                             className="flex flex-col text-guinea items-center cursor-pointer group gap-y-2 text-sm">
                            <img src={reportDelete} alt="delete"
                                 className='w-10 h-10 group-hover:scale-105 transition duration-200'/>
                            <span className='group-hover:text-spot'>Delete Backup</span>
                        </div>
                    </div>
                </div>
            </dialog>

            <dialog id={`backup-${id}-update`} className="modal">
                <div className="modal-box bg-cinder">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="text-sm">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <h3 className='font-light text-lg text-guinea font-oswald uppercase text-center'>Edit {backupName}</h3>
                    <div className="flex flex-col justify-center gap-y-4 mt-8">
                        <FieldInput callback={setBackupFolderUrl} value={backupFolderUrl} type='text' id='backup-update-folder' title='Backup folder url'/>
                        <FieldInput callback={setBackupExecutionLink} value={backupExecutionLink} type='text' id='report-doc-id' title='Link for instant backup'/>
                        <FieldInput value={name} type='text' id='backup-name' isDisabled={true} title='Backup Name'/>
                        <button onClick={updateBackupHandler}
                                className='text-water border border-water font-bold rounded-lg w-full py-2 mt-4 hover:border-water-light hover:text-water-light shadow-cinder shadow-lg'>Save
                            Changes
                        </button>
                    </div>
                </div>
            </dialog>

            <dialog id={`backup-${id}-delete`} className="modal">
                <div className="modal-box bg-cinder">
                    <h3 className='font-light text-lg text-guinea font-oswald uppercase text-center'>Confirm Deletion</h3>
                    <div className='flex mt-8 justify-around'>
                        <button onClick={() => {
                            deleteCallback(id, `backup-${id}-delete`)
                            document.getElementById(`backup-${id}`).close()
                        }}
                                className="border border-spot hover:border-spot-light text-spot hover:text-spot-light font-bold rounded-lg px-4 py-2 shadow-2xl">Yes
                            Delete
                        </button>
                        <button onClick={() => document.getElementById(`backup-${id}-delete`).close()}
                                className="border border-water hover:border-water-light text-water hover:text-water-light font-bold rounded-lg px-4 py-2 shadow-2xl">Go
                            Back
                        </button>
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default Backup;