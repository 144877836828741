import React, {useState} from 'react';
import {Link, useLocation, useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "../api/axios";
import FieldInput from "../components/FieldInput";
import {API_USERS} from "../api/endpoints";


const Registration = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [password, setPassword] = useState('')
    const [checkPassword, setCheckPassword] = useState('')

    const registrationHandler = async () => {
        const code = params.get('code')
        const toast_id = toast.loading("Registration process please wait...")

        if (password.trim() !== checkPassword.trim()) {
            toast.update(toast_id, {render: "Passwords don't match", type: "error", isLoading: false, autoClose: 3000})
            return
        }

        if (firstName.trim() === '' || lastName.trim() === '' || password.trim() === '') {
            toast.update(toast_id, {render: "All fields required", type: "error", isLoading: false, autoClose: 3000});
            return
        }

        try{
            const response = await axios.post(API_USERS, {
                "first_name": firstName,
                "last_name": lastName,
                password,
                code,
            })
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 3000})
            navigate('/login')
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'User registration failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    const keyPressedHandler = (e) => {
        if (e.code === 'Enter') {
            registrationHandler()
        }
    }

    return (
        <div className="flex h-screen justify-center items-center bg-no-repeat bg-cover">

            <div className='flex flex-col justify-center items-center w-[300px]'>
                <h1 className='text-4xl font-oswald text-guinea mb-4'>Register</h1>

                <div onKeyDown={keyPressedHandler} className='flex flex-col justify-center items-center w-full gap-y-4'>
                    <FieldInput callback={setFirstName} value={firstName} type='text' id='reg-fname' title='First Name'/>
                    <FieldInput callback={setLastName} value={lastName} type='text' id='reg-flast' title='Last Name'/>
                    <FieldInput callback={setPassword} value={password} type='password' id='reg-password' title='Password'/>
                    <FieldInput callback={setCheckPassword} value={checkPassword} type='password' id='reg-rpassword' title='Retry Password'/>
                </div>

                <button onClick={registrationHandler}
                        className='text-water border border-water font-bold rounded-lg w-full py-2 mt-4 hover:border-water-light hover:text-water-light'>Register
                </button>

                <div className='text-guinea mt-4 w-full text-center'>Or login using your existing account</div>
                <div className='text-guinea mt-4 font-bold uppercase hover:text-guinea-light'>
                    <Link to='/'>Log In</Link>
                </div>
            </div>

        </div>
    );
};

export default Registration;