import React, {useEffect, useState} from 'react';
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {API_WP_REPOSITORY_CUSTOM_VENDOR} from "../api/endpoints";
import {toast} from "react-toastify";

const WpCustomVendors = () => {
    const [search, setSearch] = useState('');
    const axios = useAxiosPrivate()
    const [vendors, setVendors] = useState([]);
    const [filteredVendors, setFilteredVendors] = useState(vendors);

    const fetchVendors = async () => {
        try {
            const response = await axios.get(API_WP_REPOSITORY_CUSTOM_VENDOR())
            setVendors(response?.data)
        }catch (err) {
            const error_data = err?.response?.data?.errors[0]
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Failed to fetch vendors.");
            }
        }
    }

    useEffect(() => {
        fetchVendors()
    }, [])

    useEffect(() => {
        const searchLower = search.toLowerCase();
    const filtered = vendors.filter(vendor =>
        vendor.name.toLowerCase().includes(searchLower) ||
        vendor.slug.toLowerCase().includes(searchLower)
    );
    setFilteredVendors(filtered);
    }, [search, vendors])

    const handleDeleteVendor = async (id) => {
        const toast_id = toast.loading("Delete vendor please wait...")
        try {
            await axios.delete(API_WP_REPOSITORY_CUSTOM_VENDOR(id))
            setVendors(vendors.filter(vendor => vendor.id !== id))
            toast.update(toast_id, {render: 'Vendor deleted', type: "success", isLoading: false, autoClose: 3000})
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Delete vendor failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    return (
        <div className='flex flex-col border border-guinea hover:border-guinea-light rounded-md p-4 h-96 min-w-[250px] overflow-x-scroll hide-scrollbar relative'>
            <div className='text-center text-guinea uppercase font-oswald text-xl'>Vendors</div>
            <div className='flex mt-4 mb-4'>
                <input onChange={(e) => setSearch(
                    e.target.value)} value={search} type="text"
                       placeholder='Search for vendor'
                       className='text-sm outline-none focus:outline-none focus:ring-0 focus:border-guinea bg-transparent border border-gray-600 rounded-lg w-full px-4 text-ultimate'/>
            </div>

            {filteredVendors && filteredVendors.map((vendor) => (
                <div key={vendor?.id} className='border border-gray-600 hover:border-huinea p-1 rounded-md text-center mt-4 hover:border-guinea relative group'>
                    <div className='text-water text-sm'><span className='text-guinea'>{vendor.name}</span>/{vendor.slug}</div>
                    <div onClick={() => handleDeleteVendor(vendor?.id)} className='text-spot hover:text-spot-light text-toolbar-size absolute z-20 border -top-7 rounded-md px-4 py-1 bg-eclipse border-gray-600 left-1/2 -translate-x-1/2 cursor-pointer hidden group-hover:block'>
                        <div>Delete</div>
                        <div className='bg-eclipse absolute w-12 h-[5px] z-30 left-1/2 -translate-x-1/2 bottom-[1px]'></div>
                        <div className='bg-eclipse border border-gray-600 w-2 h-2 absolute z-10 rotate-45'></div>
                    </div>
                </div>
            ))}
        </div>

    );
};

export default WpCustomVendors;