import React, {useState} from 'react';
import RepositoryMenu from "../components/RepositoryMenu";
import { useDropzone } from "react-dropzone";
import {toast} from "react-toastify";
import { FaUpload } from "react-icons/fa";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {API_WP_REPOSITORY, API_WP_REPOSITORY_CUSTOM_VENDOR, API_WP_REPOSITORY_GITHUB, API_WP_REPOSITORY_MANUAL_UPLOAD} from "../api/endpoints";
import repoGitHub from "../static/images/repository/repo-github.svg"
import repoManual from "../static/images/repository/repo-manual.svg"
import repoProvider from "../static/images/repository/repo-provider.svg"
import {MdClose} from "react-icons/md";
import FieldInput from "../components/FieldInput";
import { FaQuestionCircle } from "react-icons/fa";

const UploadPluginPage = () => {
    const [file, setFile] = useState(null);
    const axios = useAxiosPrivate();
    const [isUploading, setIsUploading] = useState(false);

    const [githubUrl, setGithubUrl] = useState("");
    const [githubToken, setGithubToken] = useState("");
    const [isGithubPrivate, setIsGithubPrivate] = useState(false);

    const [customVendorName, setCustomVendorName] = useState("");
    const [customVendorSlug, setCustomVendorSlug] = useState("");

    const [manualPluginName, setManualPluginName] = useState("");
    const [manualPluginSlug, setManualPluginSlug] = useState("");
    const [manualPluginVersion, setManualPluginVersion] = useState("");

    const onDrop = (acceptedFiles) => {
        if (acceptedFiles.length === 1 && acceptedFiles[0].name.endsWith(".zip")) {
          setFile(acceptedFiles[0]);
        } else {
          toast.warning("Please upload only one file at the time with type ZIP.");
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
        'application/zip': ['.zip']
        },
        multiple: false,
    });

    const handleSaveFile = async () => {
        if (!file) {
            toast.warning("No file selected.");
            return;
        }

        setIsUploading(true);

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await axios.post(API_WP_REPOSITORY(), formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            toast.success('File uploaded successfully.');
            setFile(null)
        }catch (err) {
            const error_data = err?.response?.data?.errors[0]
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Failed to upload file.");
            }
        }finally {
            setIsUploading(false);
        }
    }

    const handleSaveGitHubRepository = async () => {
        const toast_id = toast.loading("Save GitHub Repository...")
        try{
            const response = await axios.post(API_WP_REPOSITORY_GITHUB(), {
                url: githubUrl,
                is_private: isGithubPrivate,
                token: githubToken,
            })

            toast.update(toast_id, {render: 'Repository saved', type: "success", isLoading: false, autoClose: 2000})
            setGithubUrl("")
            setGithubToken("")
            setIsGithubPrivate(false)
            document.getElementById('add-git-repo-modal').close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: "Saving repository failed", type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    const handleSaveWordpressCustomVendor = async () => {
        const toast_id = toast.loading("Saving custom vendor...")
        try{
            const response = await axios.post(API_WP_REPOSITORY_CUSTOM_VENDOR(), {
                name: customVendorName,
                slug: customVendorSlug,
            })

            toast.update(toast_id, {render: 'Custom vendor saved', type: "success", isLoading: false, autoClose: 2000})
            setCustomVendorName("")
            setCustomVendorSlug("")
            document.getElementById('add-vendor-repo-modal').close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: "Saving custom vendor failed", type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    const handleManualUploadPlugin = async () => {
        if (!file) {
            toast.warning("No file selected.");
            return;
        }

        setIsUploading(true);

        const formData = new FormData();
        formData.append("file", file);
        formData.append("plugin_name", manualPluginName);
        formData.append("plugin_slug", manualPluginSlug);
        formData.append("plugin_version", manualPluginVersion);


        try {
            const response = await axios.post(API_WP_REPOSITORY_MANUAL_UPLOAD, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            })
            toast.success('File uploaded successfully.');
            setManualPluginName("")
            setManualPluginSlug("")
            setManualPluginVersion("")
            document.getElementById('add-manual-repo-modal').close()
            setFile(null)
        }catch (err) {
            const error_data = err?.response?.data?.errors[0]
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Failed to upload file.");
            }
        }finally {
            setIsUploading(false);
        }
    }

    return (
        <div>
            <RepositoryMenu tab={1}/>
            <div className='flex flex-col justify-center items-center mt-20'>
                <div className='flex gap-x-14'>
                    <div onClick={() => document.getElementById('add-git-repo-modal').showModal()} data-tip='Add GitHub Repository' className='tooltip tooltip-top cursor-pointer transition duration-200 hover:scale-105'>
                        <img src={repoGitHub} alt="repo-git" className='w-12 h-12'/>
                    </div>
                    <div onClick={() => document.getElementById('add-manual-repo-modal').showModal()} data-tip='Add Wordpress Plugin Manualy' className='tooltip tooltip-top cursor-pointer transition duration-200 hover:scale-105'>
                        <img src={repoManual} alt="repo-manual" className='w-14 h-14'/>
                    </div>
                    <div onClick={() => document.getElementById('add-vendor-repo-modal').showModal()} data-tip='Add Custom Vendor' className='tooltip tooltip-top cursor-pointer transition duration-200 hover:scale-105'>
                        <img src={repoProvider} alt="repo-provider" className='w-12 h-12'/>
                    </div>
                </div>
                <div className='w-[500px] flex justify-end mb-4'>
                    <div className='group relative'>
                        <FaQuestionCircle className='text-guinea group-hover:text-guinea-light cursor-pointer text-xl'/>
                        <div className='absolute border border-gray-600 rounded-md bg-eclipse w-[400px] -right-4 text-guinea p-4 top-8 hidden group-hover:block'>
                            <div className='bg-eclipse rotate-45 right-4 -top-2 w-4 h-4 absolute border-t border-t-gray-600 border-l border-l-gray-600'></div>
                            <span className='font-istok'>Automatically extracting metadata from plugin zip file can not be precise, specially for plugins that have number in their name, like contact-form-7, this type of plugins is better to upload in manual mode</span>
                        </div>
                    </div>
                </div>
                <div {...getRootProps({className: `border-dashed border-2 p-10 w-[500px] text-center cursor-pointer ${isDragActive ? "border-guinea" : "border-gray-600"}`})}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <div className='flex flex-col items-center gap-y-2'>
                            <FaUpload className='text-water text-[30px]'/>
                            <p className="text-water">Drag and drop a .zip file here, or click to select a file.</p>
                        </div>
                    ) : (
                        <div className='flex flex-col items-center gap-y-2'>
                            <FaUpload className='text-guinea text-[30px]'/>
                            <p className="text-guinea">Drag and drop a .zip file here, or click to select a file.</p>
                        </div>
                    )}
                </div>
                {file && (
                    <div className="mt-4">
                        <p className="text-sm text-guinea">Selected file: {file.name}</p>
                    </div>
                )}

                {
                    file ?
                        <button
                            className={`border border-water hover:border-water-light text-water hover:text-water-light rounded-md px-8 py-1 mt-8 ${isUploading ? "opacity-50" : ""}`}
                            onClick={handleSaveFile}
                            disabled={isUploading}>
                            {isUploading ? <span className='flex'>Uploading <span className="loading loading-spinner loading-xs ml-3"></span></span> : "Save File"}
                        </button> :
                        <button
                            className={`border border-gray-600 text-gray-600 rounded-md px-8 py-1 mt-8`}
                            onClick={handleSaveFile}>
                            Save File
                        </button>
                }
            </div>

            {/*GitHub Modal*/}
            <dialog id="add-git-repo-modal" className="modal ml-7">
                <div className="modal-box bg-cinder">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Add new GitHub repository</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setGithubUrl} value={githubUrl} type='text' id='git-url' title='GitHub repositor url'/>
                        <label className="inline-flex items-center cursor-pointer w-fit">
                            <input type="checkbox" onChange={() => {
                                setGithubToken("")
                                setIsGithubPrivate(!isGithubPrivate)
                            }}
                                   value={`${isGithubPrivate}`} checked={isGithubPrivate} className="sr-only peer"/>
                            <div
                                className="relative w-9 h-5 bg-gray-400 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-gray-100 after:border-gray-400 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-water"></div>
                            <span className={`ms-2 text-[12px] font-medium text-${isGithubPrivate ? "water" : "ultimate"}`}>Repository is private</span>
                        </label>
                        { isGithubPrivate && <FieldInput callback={setGithubToken} value={githubToken} type='password' id='git-token' title='GitHub access token'/>}

                        <button onClick={handleSaveGitHubRepository} className="mt-4 flex justify-center py-2 items-center gap-x-2 text-water transition duration-200 text-sm rounded w-full hover:text-water-light border border-water hover:border-water-light font-istok">
                            <span>Save GitHub repository</span></button>
                    </div>
                    {isGithubPrivate && <div className='text-toolbar-size text-guinea mt-8'>If Access token is provided each time when new release is created it will be automatically handled by Wartung tool. Access token will be encrypted and store securely</div>}
                </div>
            </dialog>

            {/*Manual Upload Modal*/}
            <dialog id="add-manual-repo-modal" className="modal ml-7">
                <div className="modal-box bg-cinder">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Add new wordpress plugin manually</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setManualPluginName} value={manualPluginName} type='text' id='repo-m-plugin-name' title='Plugin name'/>
                        <FieldInput callback={setManualPluginSlug} value={manualPluginSlug} type='text' id='repo-m-plugin-slug' title='Plugin full slug'/>
                        <FieldInput callback={setManualPluginVersion} value={manualPluginVersion} type='text' id='repo-m-plugin-version' title='Plugin version'/>

                        <div {...getRootProps({className: `border-dashed border-2 p-4 w-full text-center cursor-pointer ${isDragActive ? "border-guinea" : "border-gray-600"}`})}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <div className='flex flex-col items-center gap-y-2'>
                            <FaUpload className='text-water text-[20px]'/>
                            <p className="text-water">Drag and drop a .zip file here, or click to select a file.</p>
                        </div>
                    ) : (
                        <div className='flex flex-col items-center gap-y-2'>
                            <FaUpload className='text-guinea text-[20px]'/>
                            <p className="text-guinea">Drag and drop a .zip file here, or click to select a file.</p>
                        </div>
                    )}
                </div>
                {file && (
                    <div className="mt-4">
                        <p className="text-sm text-guinea">Selected file: {file.name}</p>
                    </div>
                )}

                {
                    file ?
                        <button
                            className={`border border-water hover:border-water-light text-water hover:text-water-light rounded-md px-8 py-1 mt-8 ${isUploading ? "opacity-50" : ""}`}
                            onClick={handleManualUploadPlugin}
                            disabled={isUploading}>
                            {isUploading ? <span className='flex'>Uploading <span className="loading loading-spinner loading-xs ml-3"></span></span> : "Save File"}
                        </button> :
                        <button
                            className={`border border-gray-600 text-gray-600 rounded-md px-8 py-1 mt-8`}
                            onClick={handleManualUploadPlugin}>
                            Save File
                        </button>
                }
                    </div>
                    <div className='mt-8 text-guinea text-sm'>Plugin full slug should be in format folder name and main file name example <span className='text-water'>contact-form-7/wp-contact-form-7</span> and Version should be in format 1.2.3</div>
                </div>
            </dialog>

            {/*Custom Vendor Modal*/}
            <dialog id="add-vendor-repo-modal" className="modal ml-7">
                <div className="modal-box bg-cinder">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Add custom vendor details</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setCustomVendorName} value={customVendorName} type='text' id='repo-vendor-name' title='Vendor name'/>
                        <FieldInput callback={setCustomVendorSlug} value={customVendorSlug} type='text' id='repo-plugin-slug' title='Plugin slug'/>
                        <button onClick={handleSaveWordpressCustomVendor} className="mt-4 flex justify-center py-2 items-center gap-x-2 text-water transition duration-200 text-sm rounded w-full hover:text-water-light border border-water hover:border-water-light font-istok">
                            <span>Save custom vendor</span></button>
                    </div>

                    <div className='text-toolbar-size text-guinea mt-8'>
                        Vendor name and vendor plugin slug should be the same like in composer.json file
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default UploadPluginPage;