import React from 'react';
import wpBedrock from "../static/images/tools/wordpress-bedrock.svg";

const ToolCard = (props) => {

    const {type, image, alt, title, callback} = props;

    return (
        <div onClick={() => callback(type)}  className="flex flex-col items-center w-[200px] justify-center text-center border border-gray-600 transition duration-200 rounded-lg bg-vulcan cursor-pointer hover:bg-cinder">
            <img src={image} alt={alt} className='h-30 w-30 py-8'/>
            <div className='bg-eclipse rounded-b-lg h-[70px] w-full py-4'>
                <span className='text-guinea text-sm'>{title}</span>
            </div>
        </div>
    );
};

export default ToolCard;