import React from 'react';

const PluginsSkeleton = (props) => {
    const { count } = props

    return (
        <>
            {Array.from({ length: count }).map((_, index) => (
                <tr key={index} className='hover:bg-cinder-light'>
                    <th className={`border-b border-b-gray-700 font-istok py-5  `}><div className='skeleton bg-eclipse animate-pulse w-4 h-4 rounded-md'></div></th>
                    <td className={`border-b border-b-gray-700 font-istok py-5  `}><div className='skeleton bg-eclipse animate-pulse w-20 h-4'></div></td>
                    <td className={`border-b border-b-gray-700 font-oswald py-5 `}><div className='skeleton bg-eclipse animate-pulse w-20 h-4'></div></td>
                    <td className={`border-b border-b-gray-700 font-oswald py-5 `}><div className='skeleton bg-eclipse animate-pulse w-20 h-4'></div></td>
                    <td className={`border-b border-b-gray-700 font-istok py-5  `}><div className='skeleton bg-eclipse animate-pulse w-36 h-4'></div></td>
                    <td className={`border-b border-b-gray-700 font-oswald py-5 `}><div className='skeleton bg-eclipse animate-pulse w-20 h-4'></div></td>
                    <td className={`border-b border-b-gray-700 font-istok py-5  `}><div className='skeleton bg-eclipse animate-pulse w-20 h-4'></div></td>
                </tr>
            ))}
        </>
    );
};

export default PluginsSkeleton;