import React, {useState} from 'react';
import reportCheck from "../static/images/sites/report-check.svg";
import reportUpdate from "../static/images/sites/report-update.svg";
import reportTest from "../static/images/sites/report-test.svg";
import reportGenerate from "../static/images/sites/report-generate.svg";
import reportEdit from "../static/images/sites/report-edit.svg";
import reportDelete from "../static/images/sites/report-delete.svg";
import reportOpen from "../static/images/sites/open-report.svg";
import {MdClose} from "react-icons/md";
import FieldInput from "./FieldInput";
import {toast} from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {API_SITE_REPORTS, API_SITE_REPORTS_GENERATE} from "../api/endpoints";
import {useParams} from "react-router-dom";

const Report = (props) => {
    const {name, type, id, deleteCallback, link} = props
    const params = useParams();
    const axios = useAxiosPrivate();
    const [reportName, setReportName] = useState(name);
    const [reportType, setReportType] = useState(type);
    const [reportDocumentLink, setReportDocumentLink] = useState(link);

    const updateReportHandler = async () => {
        const toast_id = toast.loading("Update report please wait...")
        try {
            await axios.patch(API_SITE_REPORTS(params?.orgId, params?.siteId, id), {
                report_name: reportName,
                link: reportDocumentLink,
                report_type: reportType
            })
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 3000})
            document.getElementById(`report-${id}-update`).close()
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Update report failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    const generateReportHandler = async () => {
        const toast_id = toast.loading("Generating report please wait...")
        try {
            await axios.post(API_SITE_REPORTS_GENERATE(params?.orgId, params?.siteId, id))
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 3000})
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Generate report failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    return (
        <div className='shadow-vulcan shadow-lg'>
            <div onClick={() => document.getElementById(`report-${id}`).showModal()}
                 className='flex flex-col gap-4 border border-gray-600 rounded-lg cursor-pointer group'>
                <img src={reportType === "update" ? reportUpdate : reportType === "check" ? reportCheck : reportTest} alt="report"
                     className='h-40 mt-4 transition duration-200 group-hover:scale-105'/>
                <div
                    className='text-guinea font-oswald hover:text-guinea-light flex justify-center items-center bg-vulcan p-4 uppercase w-40 text-center h-full rounded-b-lg'>{reportName}</div>
            </div>

            <dialog id={`report-${id}`} className="modal">
                <div className="modal-box bg-cinder">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="text-sm">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-light text-lg text-guinea font-oswald uppercase text-center">{reportName}</h3>
                    </div>

                    <div className="flex justify-center gap-x-8 mt-8">
                        <div onClick={generateReportHandler}
                             className="flex flex-col text-guinea items-center cursor-pointer group gap-y-2 text-sm">
                            <img src={reportGenerate} alt="generate"
                                 className='w-10 h-10 group-hover:scale-105 transition duration-200'/>
                            <span className='group-hover:text-guinea-light'>Generate report</span>
                        </div>
                        <div onClick={() => window.open(link, '_blank')}
                             className="flex flex-col text-guinea items-center cursor-pointer group gap-y-2 text-sm">
                            <img src={reportOpen} alt="open"
                                 className='w-10 h-10 group-hover:scale-105 transition duration-200'/>
                            <span className='group-hover:text-guinea-light'>Open report</span>
                        </div>
                        <div onClick={() => document.getElementById(`report-${id}-update`).showModal()}
                             className="flex flex-col text-guinea items-center cursor-pointer group gap-y-2 text-sm">
                            <img src={reportEdit} alt="update"
                                 className='w-10 h-10 group-hover:scale-105 transition duration-200'/>
                            <span className='group-hover:text-guinea-light'>Edit Report</span>
                        </div>
                        <div onClick={() => document.getElementById(`report-${id}-delete`).showModal()}
                             className="flex flex-col text-guinea items-center cursor-pointer group gap-y-2 text-sm">
                            <img src={reportDelete} alt="delete"
                                 className='w-10 h-10 group-hover:scale-105 transition duration-200'/>
                            <span className='group-hover:text-spot'>Delete Report</span>
                        </div>
                    </div>
                </div>
            </dialog>

            <dialog id={`report-${id}-update`} className="modal">
                <div className="modal-box bg-cinder">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="text-sm">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/></button>
                            </div>
                        </form>
                    </div>
                    <h3 className='font-light text-lg text-guinea font-oswald uppercase text-center'>Edit {reportName}</h3>
                    <div className="flex flex-col justify-center gap-y-4 mt-8">
                        <FieldInput callback={setReportName} value={reportName} type='text' id='report-update-name'
                                    title='Report name'/>
                        <FieldInput callback={setReportDocumentLink} value={reportDocumentLink} type='text' id='report-update-doc'
                                    title='Report document id'/>
                        <div className='flex text-guinea'>
                            <select onChange={(e) => setReportType(e.target.value)} defaultValue={reportType}
                                    className="select text-guinea w-full bg-transparent border border-gray-500 outline-none focus:outline-none focus:ring-0 focus:border-guinea">
                                <option className='text-guinea' value='check'>CHECK</option>
                                <option className='text-guinea' value='update'>UPDATE</option>
                                <option className='text-guinea' value='test'>CUSTOM</option>
                            </select>
                        </div>

                        <button onClick={updateReportHandler}
                                className='text-water hover:text-water-light border border-water font-bold rounded-lg w-full py-2 mt-4 hover:border-water-light shadow-cinder shadow-lg'>Save Changes
                        </button>
                    </div>
                </div>
            </dialog>

            <dialog id={`report-${id}-delete`} className="modal">
                <div className="modal-box bg-cinder">
                    <h3 className='font-light text-lg text-guinea font-oswald uppercase text-center'>Confirm
                        Deletion</h3>
                    <div className='flex mt-8 justify-around'>
                        <button onClick={() => {
                            deleteCallback(id, `report-${id}-delete`)
                            document.getElementById(`report-${id}`).close()
                        }}
                                className="border border-spot hover:border-spot-light text-spot hover:text-spot-light font-bold rounded-lg px-4 py-2 shadow-2xl">Yes Delete
                        </button>
                        <button onClick={() => document.getElementById(`report-${id}-delete`).close()}
                                className="border border-water hover:border-water-light text-water hover:text-water-light font-bold rounded-lg px-4 py-2 shadow-2xl">Go
                            Back
                        </button>
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default Report;