import React, {useContext, useEffect, useState} from 'react';
import OrganizationMenu from "../components/OrganizationMenu";
import FieldInput from "../components/FieldInput";
import userEditName from "../static/images/organization/settings/edit-name.svg"
import userEditPassword from "../static/images/organization/settings/edit-password.svg"
import editOrgName from "../static/images/organization/settings/edit-org-name.svg"
import editOrgUrl from "../static/images/organization/settings/edit-org-url.svg"
import editOrgImage from "../static/images/organization/settings/edit-org-image.svg"
import editOrgDelete from "../static/images/organization/settings/edit-org-delete.svg"
import {MdClose} from "react-icons/md";
import {AppContext} from "../components/AppContext";
import {toast} from "react-toastify";
import {API_ORGANIZATION} from "../api/endpoints";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {useParams} from "react-router-dom";


const Settings = () => {
    const { setNavbarData } = useContext(AppContext);
    const axios = useAxiosPrivate()
    const params = useParams();
    const {userData} = useContext(AppContext);

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");

    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [orgName, setOrgName] = useState("");
    const [orgUrl, setOrgUrl] = useState("");
    const [orgImage, setOrgImage] = useState(null);

    useEffect(() => {
        setNavbarData({"name": "Settings"})
    }, [])

    useEffect(() => {

    }, [])

    const editUserNameHandler = () => {
        toast.info('At the moment this functionality is disabled')
    }

    const editUserPasswordHandler = () => {
        toast.info('At the moment this functionality is disabled')
    }

    const editOrgNameHandler = () => {
        toast.info('At the moment this functionality is disabled')
    }

    const editOrgUrlHandler = () => {
        toast.info('At the moment this functionality is disabled')
    }

    const editOrgImageHandler = async () => {
        if (!orgImage) {
            toast.info("Please enter a valid image")
            return
        }

        const toast_id = toast.loading("Change organization image please wait...")
        try{
            const response = await axios.patch(API_ORGANIZATION(params?.orgId), {
                "image": orgImage
            }, {
                headers: {
                    "Content-Type": 'multipart/form-data',
                }
            })
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 2000})
            setOrgImage("")
            document.getElementById('edit-org-image-modal').close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data?.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: "Change organization image failed", type: "error", isLoading: false, autoClose: 3000})
                document.getElementById('edit-org-image-modal').close()
            }
        }
    }

    const editOrgDeleteHandler = () => {
        toast.info('At the moment this functionality is disabled')
    }

    return (
        <div>
            <OrganizationMenu tab={3}/>
            <div className='mt-8 flex gap-4'>
                <div className='border border-gray-600 rounded-md w-full p-4'>
                    <div className='mt-4 flex flex-col'>
                        <div>
                            <div className='text-guinea font-istok text-3xl text-center'>User Settings</div>
                        </div>
                        <div className='flex gap-8 mt-8 justify-center'>
                            <div onClick={() => document.getElementById('edit-user-name-modal').showModal()} data-tip='Edit Name' className='tooltip tooltip-bottom'><img src={userEditName} alt="use-name" className='transition duration-300 hover:scale-105 w-16 h-16 cursor-pointer'/></div>
                            <div onClick={() => document.getElementById('edit-user-password-modal').showModal()} data-tip='Change Password' className='tooltip tooltip-bottom'><img src={userEditPassword} alt="use-password" className='transition duration-300 hover:scale-105 w-16 h-16 cursor-pointer'/></div>
                        </div>
                    </div>
                </div>

                <div className='border border-gray-600 rounded-md w-full p-4'>
                    <div className='mt-4 flex flex-col'>
                        <div>
                            <div className='text-guinea font-istok text-3xl text-center'>Organization Settings</div>
                        </div>
                        <div className='flex gap-8 mt-8 justify-center'>
                            <div onClick={() => document.getElementById('edit-org-name-modal').showModal()} data-tip='Edit organization name' className='tooltip tooltip-bottom'><img src={editOrgName} alt="use-name" className='transition duration-300 hover:scale-105 w-16 h-16 cursor-pointer'/></div>
                            <div onClick={() => document.getElementById('edit-org-url-modal').showModal()} data-tip='Edit organization url' className='tooltip tooltip-bottom'><img src={editOrgUrl} alt="use-password" className='transition duration-300 hover:scale-105 w-16 h-16 cursor-pointer'/></div>
                            <div onClick={() => document.getElementById('edit-org-image-modal').showModal()} data-tip='Edit organization image' className='tooltip tooltip-bottom'><img src={editOrgImage} alt="use-password" className='transition duration-300 hover:scale-105 w-16 h-16 cursor-pointer'/></div>
                            <div onClick={() => document.getElementById('edit-org-delete-modal').showModal()} data-tip='Delete Organization' className='tooltip tooltip-bottom'><img src={editOrgDelete} alt="use-password" className='transition duration-300 hover:scale-105 w-16 h-16 cursor-pointer'/></div>
                        </div>
                    </div>
                </div>
            </div>

            <dialog id="edit-user-name-modal" className="modal">
                <div className="modal-box bg-cinder pb-8">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/></button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Edit name</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setFirstName} value={firstName} type='text' id='edit-first-name' title='Firts name'/>
                        <FieldInput callback={setLastName} value={lastName} type='text' id='edit-last-name' title='Last name'/>
                        <button onClick={editUserNameHandler} className="border border-water text-water hover:text-water-light mt-4 rounded-md py-2 w-full hover:border-water-light font-istok"> Save Changes</button>
                    </div>
                </div>
            </dialog>

            <dialog id="edit-user-password-modal" className="modal">
                <div className="modal-box bg-cinder pb-8">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Change Password</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setOldPassword} value={oldPassword} type='password' id='old-password' title='Old password'/>
                        <FieldInput callback={setPassword} value={password} type='password' id='password-change' title='New password'/>
                        <FieldInput callback={setConfirmPassword} value={confirmPassword} type='password' id='confirm-password-change' title='Confirm password'/>
                        <button onClick={editUserPasswordHandler} className="border border-water text-water hover:text-water-light mt-4 rounded-md py-2 w-full hover:border-water-light font-istok"> Save Changes</button>
                    </div>
                </div>
            </dialog>

            <dialog id="edit-org-name-modal" className="modal">
                <div className="modal-box bg-cinder pb-8">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/></button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Edit organization name</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setOrgName} value={orgName} type='text' id='edit-org-name' title='Organization name'/>
                        <button onClick={editOrgNameHandler} className="border border-water text-water hover:text-water-light mt-4 rounded-md py-2 w-full hover:border-water-light font-istok"> Save Changes</button>
                    </div>
                </div>
            </dialog>

            <dialog id="edit-org-url-modal" className="modal">
                <div className="modal-box bg-cinder pb-8">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/></button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Edit organization url</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setOrgUrl} value={orgUrl} type='text' id='edit-org-url' title='Organization url'/>
                        <button onClick={editOrgUrlHandler} className="border border-water text-water hover:text-water-light mt-4 rounded-md py-2 w-full hover:border-water-light font-istok"> Save Changes</button>
                    </div>
                </div>
            </dialog>

            <dialog id="edit-org-image-modal" className="modal">
                <div className="modal-box bg-cinder pb-8">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/></button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Edit organization image</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        {/*<FieldInput callback={setOrgImage} value={orgImage} type='file' id='edit-org-image' title='Organization image'/>*/}
                        <input onChange={(e) => setOrgImage(e.target.files[0])} type="file" id='edit-org-image'/>
                        <button onClick={editOrgImageHandler} className="border border-water text-water hover:text-water-light mt-4 rounded-md py-2 w-full hover:border-water-light font-istok"> Save Changes</button>
                    </div>
                </div>
            </dialog>

            <dialog id="edit-org-delete-modal" className="modal">
                <div className="modal-box bg-cinder">

                    <div>
                        <h3 className="font-light text-lg text-guinea text-center font-oswald">Delete organization</h3>
                    </div>
                    <div className='flex justify-around items-center mt-4'>
                        <div>
                            <button onClick={editOrgDeleteHandler}
                                    className='text-spot hover:text-redom border border-spot hover:border-redom rounded-lg px-8 py-1'>Delete
                            </button>
                        </div>
                        <div className="relative modal-action -top-3">
                            <form method="dialog">
                                <button
                                    className='text-water hover:text-water-light border border-water hover:border-water-light rounded-lg px-8 py-1'>Cancel
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className='text-sm text-spot text-center mt-4'>Warning: All sites and organizations members will be deleted</div>

                </div>
            </dialog>
        </div>
    );
};

export default Settings;