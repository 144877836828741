import React, {useEffect, useState} from 'react';
import RepositoryMenu from "../components/RepositoryMenu";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {API_WP_REPOSITORY, API_WP_REPOSITORY_DOWNLOAD_PLUGIN} from "../api/endpoints";
import {toast} from "react-toastify";
import {IoIosSearch} from "react-icons/io";
import { saveAs } from 'file-saver';
import { RiDeleteBin5Fill } from "react-icons/ri";
import WpCustomVendors from "../components/WpCustomVendors";

const RepositoryPlugins = () => {
    const [plugins, setPlugins] = useState([]);
    const axios = useAxiosPrivate()
    const [filteredPlugins, setFilteredPlugins] = useState(plugins);
    const [search, setSearch] = useState("");
    const [toDelete, setToDelete] = useState(null);

    const fetchRepositoryPlugins = async () => {
        try {
            const response = await axios.get(API_WP_REPOSITORY())
            setPlugins(response?.data)
        }catch (err) {
            const error_data = err?.response?.data?.errors[0]
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Failed to upload file.");
            }
        }
    }

    useEffect(() => {
        fetchRepositoryPlugins()
    }, [])

    useEffect(() => {
        const searchLower = search.toLowerCase();
        const filtered = plugins.filter(plugin =>
                plugin.name.toLowerCase().includes(searchLower)) ||
                plugins.filter(plugin => plugin.slug.toLowerCase().includes(searchLower));
        setFilteredPlugins(filtered);
    }, [search, plugins]);

    const downloadPlugin = async (version) => {
        try{
            const response = await axios.get(API_WP_REPOSITORY_DOWNLOAD_PLUGIN(version?.slug, version?.version), {
                responseType: "blob",
            })

            let fileName = `${version?.slug}-${version?.version}.zip`  // Fallback
            const contentDisposition = response.headers.get('Content-Disposition');
            if (contentDisposition) {
                const fileNameMatch = contentDisposition.match(/filename="?([^"]+)"?/);
                if (fileNameMatch.length > 1) {
                    fileName = fileNameMatch[1];
                }
            }

            const blob = new Blob([response.data], { type: "application/zip" })
            saveAs(blob, fileName)
        }catch (err) {
            const error_data = err?.response?.data?.errors[0]
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Failed to upload file.");
            }
        }
    }

    const deletePluginHandler = async () => {
        const toast_id = toast.loading("Delete plugin please wait...")
        try {
            await axios.delete(API_WP_REPOSITORY(toDelete?.id))
            setPlugins(plugins.filter(plugin => plugin.id !== toDelete?.id))
            toast.update(toast_id, {render: 'Plugin deleted', type: "success", isLoading: false, autoClose: 3000})
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Delete plugin failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }finally {
            document.getElementById('plugin-delete').close()
            setToDelete(null)
        }

    }

    const editPluginHandler = async (plugin) => {
        const type = plugin?.type === 'wordpress-plugin' ? 'wordpress-muplugin' : 'wordpress-plugin'

        const toast_id = toast.loading("Update plugin please wait...")
        try {
            await axios.patch(API_WP_REPOSITORY(plugin?.id), {
                type
            })

            setPlugins((prevPlugins) =>
                prevPlugins.map((updatedPlugin) =>
                    updatedPlugin.id === plugin.id ? { ...updatedPlugin, type } : updatedPlugin
                )
            );

            toast.update(toast_id, {render: 'Plugin updated', type: "success", isLoading: false, autoClose: 3000})
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Update plugin failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    return (
        <div>
            <RepositoryMenu tab={2}/>
            <div className='flex mt-10'>
                <input onChange={(e) => setSearch(
                    e.target.value)} value={search} type="text"
                       placeholder='Search for plugin'
                       className='text-sm outline-none focus:outline-none focus:ring-0 focus:border-guinea bg-transparent border border-gray-600 rounded-lg w-[400px] px-4 py-2 text-ultimate'/>
                <IoIosSearch className='w-7 h-7 -ml-9 mt-1 cursor-pointer'/>
            </div>
            <div className='flex mt-10 gap-x-4'>
                <div className="grid grid-cols-3 gap-4 h-fit">
                    {filteredPlugins &&
                        filteredPlugins.map((plugin) => (
                            <div key={plugin?.id} className="border border-gray-600 rounded-lg shadow-lg p-6 transition-all duration-300 hover:border-guinea hover:shadow-xl relative group">
                                <div onClick={() => {
                                    setToDelete(plugin)
                                    document.getElementById('plugin-delete').showModal()
                                }} className='absolute right-4 top-4 hidden group-hover:flex text-spot-light cursor-pointer'><RiDeleteBin5Fill className='hover:text-spot'/></div>
                                <label data-tip='Set as mu plugin' className=" tooltip tooltip-top items-center cursor-pointer absolute hidden group-hover:inline-flex">
                                    <input type="checkbox" onClick={() => editPluginHandler(plugin)}
                                            checked={plugin?.type === 'wordpress-muplugin'} className="sr-only peer"/>
                                    <div
                                        className="relative w-9 h-5 bg-gray-400 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-gray-100 after:border-gray-400 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-water"></div>
                                    {/*<span className={`ms-2 text-[12px] font-medium text-${true ? "water" : "ultimate"}`}>Connected only</span>*/}
                                </label>
                                <h2 className="text-center text-2xl font-semibold text-guinea mb-2">{plugin?.name}</h2>
                                <p className="text-center text-gray-500 text-lg">{plugin?.slug}</p>
                                <p className="mt-4 text-sm text-gray-400 text-center line-clamp-2"> {plugin?.description} </p>
                                <div className="mt-4 text-center text-sm"><span className='text-ultimate'>Composer: </span><span className="text-water font-medium">{plugin?.repository_slug}</span></div>
                                <div className="mt-4 flex justify-center gap-2 flex-wrap">
                                    {plugin?.versions &&
                                        plugin?.versions.map((version) => (
                                            <button
                                                onClick={() => downloadPlugin(version)}
                                                key={version?.id}
                                                className="tooltip border border-water hover:border-water-light text-water hover:text-water-light rounded-md text-toolbar-size px-2 cursor-pointer transition-all duration-300"
                                                data-tip={`Download v${version?.version}`}>
                                                {version?.version}
                                            </button>
                                        ))}
                                </div>
                            </div>
                        ))}
                </div>

                <WpCustomVendors/>
            </div>


            <dialog id={`plugin-delete`} className="modal">
                <div className="modal-box bg-cinder">

                    <div>
                        <h3 className="font-light text-lg text-guinea text-center font-oswald">Delete {toDelete?.name}</h3>
                    </div>
                    <div className='flex justify-around items-center mt-4'>
                        <div>
                            <button onClick={deletePluginHandler}
                                    className='text-spot hover:text-redom border border-spot hover:border-redom rounded-lg px-8 py-1'>Delete
                            </button>
                        </div>
                        <div className="relative modal-action -top-3">
                            <form method="dialog">
                            <button
                                    className='text-water hover:text-water-light border border-water hover:border-water-light rounded-lg px-8 py-1'>Cancel
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className='text-center text-guinea text-toolbar-size mt-4'>{toDelete?.name} plugin will be deleted and all its versions</div>
                </div>
            </dialog>

        </div>
    );
};

export default RepositoryPlugins;