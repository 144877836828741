import React from "react";

const WpPlugin = (props) => {
    const {
        index,
        name,
        currentVersion,
        lastVersion,
        vulnerability,
        cvssScore,
        severity,
        active} = props

    const getRowColor = () => {
        if (severity === 'Critical' || severity === 'High') {
            return "text-spot"
        }
        if (!active) {
            return "text-gray-600"
        }
        return "text-ultimate"
    }

    return (
        <>
            <tr className='hover:bg-cinder-light'>
                <th className={`border-b border-b-gray-700 font-istok py-5 ${getRowColor()}`}>{index}</th>
                <td className={`border-b border-b-gray-700 font-istok py-5 ${getRowColor()}`}>{name}</td>
                <td className={`border-b border-b-gray-700 font-oswald py-5 ${getRowColor()}`}>{currentVersion}</td>
                <td className={`border-b border-b-gray-700 font-oswald py-5 ${getRowColor()}`}>{lastVersion}</td>
                <td className={`border-b border-b-gray-700 font-istok py-5 ${getRowColor()}`}>{vulnerability}</td>
                <td className={`border-b border-b-gray-700 font-oswald py-5 ${getRowColor()}`}>{cvssScore}</td>
                <td className={`border-b border-b-gray-700 font-istok py-5 ${getRowColor()}`}>{severity}</td>
            </tr>
        </>
    );
};

export default WpPlugin;