import React, {useEffect, useState} from 'react';
import SiteMenu from "../components/SiteMenu";
import {useParams} from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {toast} from "react-toastify";
import WpPlugin from "../components/WpPlugin";
import {API_SITE_PLUGINS} from "../api/endpoints";
import PluginsSkeleton from "../components/skeletons/PluginsSkeleton";

const SitePlugins = () => {
    const params = useParams()
    const axios = useAxiosPrivate()
    const [sitePlugins, setSitePlugins] = useState([])

    const fetchSitePlugins = async () => {
        try{
            const response = await axios(API_SITE_PLUGINS(params?.orgId, params?.siteId))
            setSitePlugins(response?.data)
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Fetch site plugins failed")
            }
        }
    }

    useEffect(() => {
        fetchSitePlugins()
    }, [])

    return (
        <div className='relative'>
            <SiteMenu tab={2}/>
            <div className='flex justify-end items-center text-guinea font-oswald mt-20 gap-x-14'></div>
            <div className='mt-4 p-4'>
                <div className="overflow-x-auto">
                    <table className="table">
                        <thead>
                        <tr>
                            <th className='text-[14px] border-b border-b-gray-700'></th>
                            <th className='text-[14px] border-b border-b-gray-700 text-guinea uppercase hover:text-guinea-light'>Name</th>
                            <th className='text-[14px] border-b border-b-gray-700 text-guinea uppercase hover:text-guinea-light'>Current Version</th>
                            <th className='text-[14px] border-b border-b-gray-700 text-guinea uppercase hover:text-guinea-light'>Last Version</th>
                            <th className='text-[14px] border-b border-b-gray-700 text-guinea uppercase hover:text-guinea-light'>Vulnerability</th>
                            <th className='text-[14px] border-b border-b-gray-700 text-guinea uppercase hover:text-guinea-light'>Cvss Score</th>
                            <th className='text-[14px] border-b border-b-gray-700 text-guinea uppercase hover:text-guinea-light'>Severity</th>
                        </tr>
                        </thead>
                        <tbody>

                        { sitePlugins && sitePlugins.length > 0 ? (
                            sitePlugins.map((plugin, index) => (
                            <WpPlugin key={index}
                                      index={index + 1}
                                      name={plugin?.name}
                                      currentVersion={plugin?.current_version}
                                      lastVersion={plugin?.available_version}
                                      vulnerability={plugin?.vulnerabilities ? plugin?.vulnerabilities.sort((a, b) => b.cvss_score - a.cvss_score)[0]?.description : " "}
                                      cvssScore={plugin?.vulnerabilities ? plugin?.vulnerabilities.sort((a, b) => b.cvss_score - a.cvss_score)[0]?.cvss_score : " "}
                                      severity={plugin?.vulnerabilities ? plugin?.vulnerabilities.sort((a, b) => b.cvss_score - a.cvss_score)[0]?.severity : " "}
                                      active={plugin?.active}
                                      pluginSlug={plugin?.slug}
                            />
                        ))): <PluginsSkeleton count={10} />}

                        </tbody>
                        <tfoot>
                        <tr>
                            <th></th>
                            <th className='text-[14px] text-guinea uppercase hover:text-guinea-light'>Name</th>
                            <th className='text-[14px] text-guinea uppercase hover:text-guinea-light'>Current Version</th>
                            <th className='text-[14px] text-guinea uppercase hover:text-guinea-light'>Last Version</th>
                            <th className='text-[14px] text-guinea uppercase hover:text-guinea-light'>Vulnerability</th>
                            <th className='text-[14px] text-guinea uppercase hover:text-guinea-light'>Cvss Score</th>
                            <th className='text-[14px] text-guinea uppercase hover:text-guinea-light'>Severity</th>
                        </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default SitePlugins;