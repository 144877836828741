import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import SiteMenu from "../components/SiteMenu";
import {API_SITE_BACKUPS, API_SITE_BACKUPS_SCHEDULE_INFO} from "../api/endpoints";
import {toast} from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import createReport from "../static/images/sites/add-new-report.svg";
import {MdClose} from "react-icons/md";
import FieldInput from "../components/FieldInput";
import Backup from "../components/Backup";
import {AppContext} from "../components/AppContext";

const SiteBackups = () => {
    const params = useParams()
    const axios = useAxiosPrivate()
    const [backups, setBackups] = useState([])
    const {setNavbarData, navbarData} = useContext(AppContext);
    const [backupFolderUrl, setBackupFolderUrl] = useState("")
    const [backupExecutionLink, setBackupExecutionLink] = useState("")
    const [scheduleInfo, setScheduleInfo] = useState({});


    const fetchBackupData = async () => {
        try{
            const response = await axios.get(API_SITE_BACKUPS(params?.orgId, params?.siteId))
            setBackups(response?.data)
            if (!navbarData?.name){
                setNavbarData({name: response?.data?.[0]?.backup_name || ''})
            }
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Fetch backups failed")
            }
        }
    }

    const fetchBackupsScheduleInfo = async (backup_id) => {
        try{
            const response = await axios.get(API_SITE_BACKUPS_SCHEDULE_INFO(params?.orgId, params?.siteId, backup_id))
            setScheduleInfo(response?.data)
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Fetch backups schedule info failed")
            }
        }
    }

    useEffect(() => {
        fetchBackupData()
    }, []);

    useEffect(() => {
        const backup_id = backups[0]?.id
        if (backup_id) {
            fetchBackupsScheduleInfo(backup_id)
        }
    }, [backups])

    const creteBackupHandler = async () => {
        if (!backupFolderUrl) {
            toast.info("Please enter a valid folder url")
            return
        }

        const toast_id = toast.loading("Send request please wait...")
        try{
            const response = await axios.post(API_SITE_BACKUPS(params?.orgId, params?.siteId), {
                folder_url: backupFolderUrl,
                execution_url: backupExecutionLink,
            })
            setBackupFolderUrl("")
            setBackups(backups => [...backups, response?.data])
            toast.update(toast_id, {render: "Backup creation success", type: "success", isLoading: false, autoClose: 3000})
            document.getElementById("backup-create").close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Backup creation failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    const backupDeleteHandler = async (backup_id, modal_id) => {
        const toast_id = toast.loading("Backup Delete Please wait...")
        try{
            await axios.delete(API_SITE_BACKUPS(params?.orgId, params?.siteId, backup_id))
            setBackups(backups.filter(backup => backup.id !== backup_id))
            toast.update(toast_id, {render: "Backup deleted successfully.", type: "success", isLoading: false, autoClose: 3000})
        }catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Deletion failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
        document.getElementById(modal_id).close()
    }

    return (
        <section>
            <SiteMenu tab={4}/>
            <div className='flex gap-x-8 mt-24'>
                <div className='flex gap-4 flex-wrap'>
                    {backups && backups.map(backup => (
                        <Backup key={backup.id}
                                id={backup.id}
                                name={backup.backup_name}
                                folder_url={backup.folder_url}
                                execution_url={backup.execution_url}
                                deleteCallback={backupDeleteHandler}
                        />
                    ))}
                    {backups.length === 0 &&
                        <div onClick={() => document.getElementById("backup-create").showModal()}
                             className='flex justify-center h-[250px] items-center gap-4 border border-gray-600 rounded-lg cursor-pointer group tooltip'
                             data-tip="Creat New Backup">
                            <img src={createReport} alt="add"
                                 className='h-40 mt-4 transition duration-200 group-hover:scale-105'/>
                        </div>
                    }
                </div>
                {backups && backups.length > 0 && scheduleInfo && (
                    <div className="h-[250px]  p-6 bg-cinder rounded-lg shadow-xl border border-gray-600 hover:border-guinea">
                        <div className="flex items-center justify-between mb-6">
                            <div className="text-2xl font-bold text-guinea-light">Auto Backup</div>
                            <div className="bg-guinea text-eclipse-dark px-3 py-1 rounded-full text-sm font-semibold">{scheduleInfo?.status ? 'Active' : 'Disable'}</div>
                        </div>
                        <div className="bg-cinder-table p-4 rounded-lg mb-4">
                            <div className="text-sm text-guinea">
                                Backup name:
                                <span className="ml-2 font-medium text-water">{backups[0]?.backup_name}</span>
                            </div>
                            <div className="text-sm text-guinea mt-2">
                                Last executed time:
                                <span className="ml-2 font-medium text-water">{scheduleInfo?.last_execution_time}</span>
                            </div>
                            <div className="text-sm text-guinea mt-2">
                                Next execution time:
                                <span className="ml-2 font-medium text-water-light">{scheduleInfo?.next_run_time}</span>
                            </div>
                        </div>
                        <div className="flex items-center justify-between">
                            <div className="text-sm text-guinea-light">
                                Execution count:
                                <span className="ml-2 font-bold text-ultimate">{scheduleInfo?.execution_count}</span>
                            </div>
                        </div>
                    </div>
                )}

                <dialog id="backup-create" className="modal">
                    <div className="modal-box bg-cinder">
                        <div className="modal-action absolute right-4 -top-2">
                            <form method="dialog">
                                <div className="text-sm">
                                    <button className=""><MdClose
                                        className='text-guinea text-2xl hover:text-guinea-light'/>
                                    </button>
                                </div>
                            </form>
                        </div>
                        <div>
                            <h3 className="font-light text-lg text-guinea font-oswald uppercase">Create New Backup</h3>
                        </div>

                        <div className='flex flex-col gap-y-4 mt-8'>
                            <FieldInput callback={setBackupFolderUrl} value={backupFolderUrl} type='text' id='report-doc-id' title='Backup folder url'/>
                            <FieldInput callback={setBackupExecutionLink} value={backupExecutionLink} type='text' id='report-doc-id' title='Link for instant backup'/>
                            <button onClick={creteBackupHandler}
                                    className='text-water border border-water font-bold rounded-lg w-full py-2 mt-4 hover:border-water-light hover:text-water-light'>Create
                                New Backup
                            </button>
                        </div>
                    </div>
                </dialog>
            </div>
        </section>
    );
};

export default SiteBackups;