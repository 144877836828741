import React, {useContext, useEffect} from 'react';
import {AppContext} from "../components/AppContext";
import wpBedrock from "../static/images/tools/wordpress-bedrock.svg"
import wpPlugin from "../static/images/tools/wp-plugin.svg"
import hubspot from "../static/images/tools/hubspot-boilerplate.svg"
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import ToolCard from "../components/ToolCard";

const Tools = () => {
    const { setNavbarData } = useContext(AppContext);
    const navigate = useNavigate();

    useEffect(() => {
        setNavbarData({name: 'Tools'})
    }, [setNavbarData]);

    const toolClickHandler = (tool_type) => {
        toast.success(`Clicked on tool type ${tool_type}`);
    }

    return (
        <section className='flex gap-4 flex-wrap'>
            <ToolCard
                callback={toolClickHandler}
                type='wpBedrock'
                image={wpBedrock}
                alt='wp-boilerpale'
                title='Wordpress Bedrock Boilerplate'/>


            <div onClick={() => toolClickHandler("wpPlugin")}
                 className="flex flex-col items-center w-[200px] justify-center text-center border border-gray-600 transition duration-200 rounded-lg bg-vulcan cursor-pointer hover:bg-cinder">
                <img src={wpPlugin} alt="wp-boilerpale" className='h-30 w-30 py-8'/>
                <div className='bg-eclipse rounded-b-lg h-[70px] w-full py-4'>
                    <span className='text-guinea text-sm'>Gutenberg Plugin Boilerplate</span>
                </div>
            </div>

            <div onClick={() => toolClickHandler("hbBoilerplate")}
                 className="flex flex-col items-center w-[200px] justify-center text-center border border-gray-600 transition duration-200 rounded-lg bg-vulcan cursor-pointer hover:bg-cinder">
                <img src={hubspot} alt="wp-boilerpale" className='h-30 w-30 py-8'/>
                <div className='bg-eclipse rounded-b-lg h-[70px] w-full py-4'>
                    <span className='text-guinea text-sm'>HubSpot Boilerplate Theme</span>
                </div>
            </div>

        </section>
    );
};

export default Tools;