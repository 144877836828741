import React, {useContext, useEffect, useState} from 'react';
import OrganizationMenu from "../components/OrganizationMenu";
import invitation from "../static/images/organization/member-invitation.svg"
import {useParams} from "react-router-dom";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import owner from "../static/images/organization/roles/owner.svg"
import admin from "../static/images/organization/roles/admin.svg"
import developer from "../static/images/organization/roles/developer.svg"
import member from "../static/images/organization/roles/member.svg"
import quest from "../static/images/organization/roles/quest.svg"
import {
    API_ORGANIZATION_DELETE_MEMBER, API_ORGANIZATION_EDIT_MEMBER,
    API_ORGANIZATION_MEMBERS,
    API_ORGANIZATION_MEMBERS_INVITATION,
} from "../api/endpoints";
import {toast} from "react-toastify";
import {MdClose} from "react-icons/md";
import FieldInput from "../components/FieldInput";
import {AppContext} from "../components/AppContext";
import MembersSkeleton from "../components/skeletons/MembersSkeleton";

const Members = () => {
    const params = useParams();
    const axios = useAxiosPrivate()
    const { setNavbarData } = useContext(AppContext);
    const [members, setMembers] = useState([]);
    const [email, setEmail] = useState("");
    const [role, setRole] = useState("default");
    const [memberToDelete, setMemberToDelete] = useState("");
    const [memberToEdit, setMemberToEdit] = useState("");

    const fetchMembers = async () => {
        try{
            const response = await axios(API_ORGANIZATION_MEMBERS(params?.orgId))
            setMembers(response?.data)
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.error(error_data?.detail)
            }else {
                toast.error("Fetch organization members failed")
            }
        }
    }

    useEffect(() => {
        setNavbarData({"name": "Members"})
        fetchMembers()
    }, [])

    const getBaseUrl = () => {
        const { protocol, hostname, port } = window.location;
        return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
    }

    const inviteMemberHandler = async () => {
        if (!role) {
            toast.info("Please select role")
            return
        }
        const toast_id = toast.loading("Adding new site please wait...")
        try{
            const response = await axios.post(API_ORGANIZATION_MEMBERS_INVITATION(params?.orgId), {
                "email": email,
                "role": role,
                "base_url": `${getBaseUrl()}/confirm-invitation`,
                "redirect_link": `${getBaseUrl()}/registration`
            })
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 2000})
            setEmail('')
            setRole('default')
            document.getElementById('invite-modal').close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data?.detail, type: "error", isLoading: false, autoClose: 2000})
            }else {
                toast.update(toast_id, {render: "Send invitation failed", type: "error", isLoading: false, autoClose: 2000})
                document.getElementById('invite-modal').close()
            }
        }
    }

    const deleteMemberHandler = async () => {
        const toast_id = toast.loading("Delete member please wait...")
        try{
            const response = await axios.post(API_ORGANIZATION_DELETE_MEMBER(params?.orgId), {
                "member_id": memberToDelete,
            })
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 2000})
            const updatedMembers = members.filter(member => member.id !== memberToDelete)
            setMembers(updatedMembers)
            setMemberToDelete("")
            document.getElementById('delete-member').close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data?.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: "Delete member failed", type: "error", isLoading: false, autoClose: 3000})
                document.getElementById('delete-member').close()
            }
        }
    }

    const editMemberHandler = async () => {
        const toast_id = toast.loading("Edit member please wait...")
        try{
            const response = await axios.patch(API_ORGANIZATION_EDIT_MEMBER(params?.orgId), {
                "member_id": memberToEdit,
                "role": role
            })
            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 2000})
            const updatedMembers = members.map(member => member.id === memberToEdit ? { ...member, role: role } : member );
            setMembers(updatedMembers)
            setMemberToEdit("")
            setRole('default')
            document.getElementById('edit-member').close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data?.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: "Change member role failed", type: "error", isLoading: false, autoClose: 3000})
                document.getElementById('edit-member').close()
            }
        }
    }

    const getRoleTypeImage = (role) => {
        switch (role) {
            case "owner":
                return owner
            case "admin":
                return admin
            case "developer":
                return developer
            case "member":
                return member
            default:
                return quest
        }
    }

    return (
        <div>
            <OrganizationMenu tab={2}/>
            <div>
                <div className='flex justify-end mt-8 cursor-pointer'>
                    <div onClick={() => document.getElementById('invite-modal').showModal()} data-tip='Add New Member'
                         className='tooltip tooltip-left'>
                        <img src={invitation} alt="invitation"
                             className='w-12 h-12 transition duration-200 hover:scale-105'/>
                    </div>

                </div>
                <div className='flex flex-col'>
                    <div className='text-guinea text-center text-3xl uppercase mt-8 mb-8'>Members</div>
                    <div className='flex flex-col border border-gray-600 rounded-lg p-1 bg-cinder-table'>
                        <div className='flex justify-between border-b border-b-gray-600 py-6 font-bold text-ultimate'>
                            <span className='w-full text-center uppercase'>Type</span>
                            <span className='w-full text-center uppercase'>Name</span>
                            <span className='w-full text-center uppercase'>Email</span>
                            <span className='w-full text-center uppercase'>Role</span>
                            <span className='w-full text-center uppercase'>Action</span>
                        </div>

                        {members && members.length > 0 ? (
                            members.map((member, index) => (
                                <div key={index}
                                     className='flex py-6 border-b border-b-gray-600 hover:bg-vulcan cursor-pointer items-center'>
                                    <span className='w-full text-center text-guinea flex justify-center'><img src={getRoleTypeImage(member.role)} alt="role-image" className='w-8 h-8'/></span>
                                    <span className='w-full text-center text-guinea'>{member.name}</span>
                                    <span className='w-full text-center text-guinea'>{member.email}</span>
                                    <span className='w-full text-center text-guinea capitalize'>{member.role}</span>
                                    <span className='w-full text-center text-guinea flex justify-center gap-x-4'>
                                        <button onClick={() => {
                                            setMemberToEdit(member.id)
                                            document.getElementById('edit-member').showModal()
                                        }} className='border border-ultimate text-sm rounded-md px-2 py-[2px] text-ultimate cursor-pointer hover:text-water-light hover:border-water-light'>Edit</button>

                                        <button onClick={() => {
                                            setMemberToDelete(member.id)
                                            document.getElementById('delete-member').showModal()
                                        }} className='border border-ultimate text-sm rounded-md px-2 py-[2px] text-ultimate cursor-pointer hover:text-spot hover:border-spot'>Delete</button>
                                    </span>
                                </div>
                            ))): <MembersSkeleton count={4}/>}

                        <div className='flex justify-between py-6 font-bold text-ultimate'>
                            <span className='w-full text-center uppercase'>Type</span>
                            <span className='w-full text-center uppercase'>Name</span>
                            <span className='w-full text-center uppercase'>Email</span>
                            <span className='w-full text-center uppercase'>Role</span>
                            <span className='w-full text-center uppercase'>Action</span>
                        </div>
                    </div>
                </div>
            </div>

            <dialog id="invite-modal" className="modal">
                <div className="modal-box bg-cinder pb-8">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Invite new member</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setEmail} value={email} type='text' id='invitation-email' title='Email'/>
                        <div className='flex text-guinea'>
                            <select onChange={(e) => setRole(e.target.value)} value={role}
                                    className="select w-full bg-transparent border border-gray-500 outline-none focus:outline-none focus:ring-0 focus:border-guinea text-guinea">
                                <option disabled value='default'>Select member role</option>
                                <option className='text-guinea' value='admin'>ADMIN</option>
                                <option className='text-guinea' value='developer'>DEVELOPER</option>
                                <option className='text-guinea' value='member'>MEMBER</option>
                                <option className='text-guinea' value='guest'>GUEST</option>
                            </select>
                        </div>
                        <button onClick={inviteMemberHandler}
                                className="border border-water text-water hover:text-water-light mt-4 rounded-md py-2 w-full hover:border-water-light font-istok">
                            Send Invitation
                        </button>
                    </div>
                </div>
            </dialog>

            <dialog id="edit-member" className="modal">
                <div className="modal-box bg-cinder pb-8">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Edit organization member</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <div className='flex text-guinea'>
                            <select onChange={(e) => setRole(e.target.value)} value={role} className="select text-guinea w-full bg-transparent border border-gray-500 outline-none focus:outline-none focus:ring-0 focus:border-guinea">
                                <option disabled value='default'>Select member role</option>
                                <option value='admin'>ADMIN</option>
                                <option value='developer'>DEVELOPER</option>
                                <option value='member'>MEMBER</option>
                                <option value='guest'>GUEST</option>
                            </select>
                        </div>
                        <button onClick={editMemberHandler}
                                className="border border-water text-water hover:text-water-light mt-4 rounded-md py-2 w-full hover:border-water-light font-istok">
                            Send Invitation
                        </button>
                    </div>
                </div>
            </dialog>

            <dialog id={`delete-member`} className="modal">
                <div className="modal-box bg-cinder">

                    <div>
                        <h3 className="font-light text-lg text-guinea text-center font-oswald">Member will be deleted from organization</h3>
                    </div>
                    <div className='flex justify-around items-center mt-4'>
                        <div>
                            <button onClick={deleteMemberHandler}
                                    className='text-spot hover:text-redom border border-spot hover:border-redom rounded-lg px-8 py-1'>Delete
                            </button>
                        </div>
                        <div className="relative modal-action -top-3">
                            <form method="dialog">
                                <button
                                    className='text-water hover:text-water-light border border-water hover:border-water-light rounded-lg px-8 py-1'>Cancel
                                </button>
                            </form>
                        </div>
                    </div>

                </div>
            </dialog>
        </div>
    );
};

export default Members;