import React from 'react';
import {Link, useNavigate} from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <div className='flex flex-col justify-center items-center h-screen gap-y-4'>
            <div className='text-7xl font-light font-oswald text-guinea uppercase'>Nothing here go away :)</div>
            <div onClick={handleBack} className='text-lg font-light font-oswald text-water uppercase cursor-pointer hover:text-water-light'>Go back</div>
        </div>
    );
};

export default NotFound;