import React, {useState} from 'react';
import {Link, useNavigate} from "react-router-dom";
import axios from "../api/axios";
import useAuthService from "../hooks/useAuthService";
import {API_AUTH_TOKEN, API_ORGANIZATION, API_USER_RESET_PASSWORD_REQUEST} from "../api/endpoints";
import {toast} from "react-toastify";
import FieldInput from "../components/FieldInput";
import {MdClose} from "react-icons/md";

const Login = () => {

    const authService = useAuthService()
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [resetEmail, setResetEmail] = useState("");
    // const [resetPassword, setResetPassword] = useState("");
    // const [resetConfirmPassword, setResetConfirmPassword] = useState("");

    const loginHandler = async  () => {
        const toast_id = toast.loading("Login please wait...")
        try {
            const response = await axios.post(API_AUTH_TOKEN, {
                email,
                password,
            }, {
                withCredentials: true
            });

            const accessToken = response?.data?.access
            const refreshToken = response?.data?.refresh

            authService.setAccessToken(accessToken)
            authService.setRefreshToken(refreshToken)

            setEmail('')
            setPassword('')

            toast.update(toast_id, {render: 'Success', type: "success", isLoading: false, autoClose: 3000})
            navigate('/organizations')

        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: 'Login failed', type: "error", isLoading: false, autoClose: 3000})
            }
        }
    }

    const resetPasswordRequestHandler = async () => {

        // if (!resetPassword || !resetConfirmPassword) {
        //     toast.info("All fields are required.")
        //     return
        // }
        //
        // if (resetPassword !== resetConfirmPassword) {
        //     toast.info("Confirm passwords do not match")
        //     return
        // }

        if (!resetEmail) {
            toast.info("Email is required")
            return
        }

        const getBaseUrl = () => {
            const { protocol, hostname, port } = window.location;
            return `${protocol}//${hostname}${port ? `:${port}` : ''}`;
        }

        const toast_id = toast.loading("Send request please wait...")
        try{
            const response = await axios.post(API_USER_RESET_PASSWORD_REQUEST, {
                "reset_email": resetEmail,
                "redirect_link": `${getBaseUrl()}/reset-password`
            })
            toast.update(toast_id, {render: 'Please verify your email', type: "success", isLoading: false, autoClose: 4000})
            setResetEmail("")
            document.getElementById('reset-password-request-modal').close()
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data?.detail, type: "error", isLoading: false, autoClose: 3000})
            }else {
                toast.update(toast_id, {render: "Reset password failed", type: "error", isLoading: false, autoClose: 3000})
                document.getElementById('reset-password-request-modal').close()
            }
        }
    }

    return (
        <div className="flex h-screen justify-center items-center bg-cinder">

            <div className='flex flex-col justify-center items-center gap-y-4 w-[300px]'>
                <h1 className='text-4xl font-oswald text-guinea mb-4'>Login</h1>

                <FieldInput callback={setEmail} value={email} type='email' id='login-email' title='Email Address'/>
                <FieldInput callback={setPassword} value={password} type='password' id='login-password'
                            title='Password'/>

                <button onClick={loginHandler}
                        className='text-water border border-water font-bold rounded-lg w-full py-2 mt-4 hover:border-water-light hover:text-water-light'>Login
                </button>

                <div onClick={() => document.getElementById('reset-password-request-modal').showModal()} className='text-guinea hover:text-water-light cursor-pointer'>Forgot your password?</div>

                <div className='text-guinea mt-4 font-bold text-sm uppercase hover:text-guinea-light'>
                    <Link to='/organization-register'>Register new organization</Link>
                </div>
                {/*<div className='text-guinea mt-4 font-bold uppercase hover:text-guinea-light'>*/}
                {/*    <Link to='/verification'>Confirm email address</Link>*/}
                {/*</div>*/}
            </div>

            <dialog id="reset-password-request-modal" className="modal">
                <div className="modal-box bg-cinder pb-8">
                    <div className="modal-action absolute right-4 -top-2">
                        <form method="dialog">
                            <div className="tooltip tooltip-left text-sm" data-tip="Click or press ESC">
                                <button className=""><MdClose className='text-guinea text-2xl hover:text-guinea-light'/></button>
                            </div>
                        </form>
                    </div>
                    <div>
                        <h3 className="font-bold text-lg text-guinea font-oswald">Reset your password</h3>
                    </div>
                    <div className='flex flex-col gap-y-4 mt-8'>
                        <FieldInput callback={setResetEmail} value={resetEmail} type='email' id='reset-password' title='Email'/>
                        <button onClick={resetPasswordRequestHandler} className="border border-water text-water hover:text-water-light mt-4 rounded-md py-2 w-full hover:border-water-light font-istok">Reset password</button>
                    </div>
                </div>
            </dialog>
        </div>
    );
};

export default Login;