import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from "../components/AppContext";
import {useNavigate, useParams} from "react-router-dom";
import CanvasJSReact from '@canvasjs/react-charts';
import OrganizationMenu from "../components/OrganizationMenu";
import { API_ORGANIZATION_INFO } from "../api/endpoints";
import { toast } from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import organization from "../static/images/organization/organization.svg"

const Dashboard = () => {
    const { setNavbarData } = useContext(AppContext);
    const params = useParams();
    const axios = useAxiosPrivate();
    const navigate = useNavigate();
    const [organizationInfo, setOrganizationInfo] = useState(null);
    const [sitesPerYear, setSitesPerYear] = useState(null);
    const BASE_URL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        setNavbarData({ name: 'Dashboard' });
    }, [setNavbarData]);

    const fetchOrganizationInfo = async () => {
        try {
            const response = await axios(API_ORGANIZATION_INFO(params?.orgId));
            setOrganizationInfo(response?.data);
            setSitesPerYear(response?.data?.sites?.sites_per_year)
        } catch (err) {
            const error_data = err?.response?.data?.errors?.[0] || null;
            if (error_data) {
                toast.error(error_data?.detail);
            } else {
                toast.error("Fetch organization info failed");
            }
        }
    };

    useEffect(() => {
        fetchOrganizationInfo();
    }, []);

    useEffect(() => {
        const observer = new MutationObserver(() => {
          const element = document.querySelector('.canvasjs-chart-credit');
          if (element) {
            element.remove();
          }
        });
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
        return () => observer.disconnect();
    }, []);

    const sitesLabelOnClickHandler = (label, value) => {
        switch (label) {
            case 'Vulnerable':
                setTimeout(() => {
                    navigate(`/organization/${params?.orgId}/sites-vulnerable`);
                }, 1000)
                break;
            case 'Connected':
                console.log("Connected")
                break;
            case 'Disconnected':
                console.log("Disconnected")
                break;
        }
    }

    const CanvasJS = CanvasJSReact.CanvasJS;
    const CanvasJSChart = CanvasJSReact.CanvasJSChart;
    CanvasJS.addColorSet('sites', [
                    "#70ae9a",
                    "#a69065",
                    "#ed7a7a"
                ])

    CanvasJS.addColorSet('members', [
                    "#8d7b54",
                    "#70ae9a",
                    "#aaaab4",
                    "#ed7a7a"
                ])

    CanvasJS.addColorSet('wordpress', [
                    "#8d7b54",
                    "#a69065",
                    "#70ae9a",
                    "#83c6b0",
                ])

    const optionsSites = {
        animationEnabled: true,
        exportEnabled: false,
        theme: "dark2",
        title:{
            text: "Wordpress Sites",
            fontFamily: "Oswald, sans-serif",
            fontColor: "#8d7b54"
        },
        backgroundColor: "transparent",
        colorSet: 'sites',
        data: [{
            type: "pie",
            indexLabel: "{label}: {y}",
            indexLabelFontColor: "#8d7b54",
            startAngle: -90,
            dataPoints: [
                { y: organizationInfo?.sites?.connected, label: "Connected" },
                { y: organizationInfo?.sites?.disconnected, label: "Disconnected" },
                { y: organizationInfo?.sites?.vulnerable, label: "Vulnerable" },
            ],
            click: (e) => {
                sitesLabelOnClickHandler(e.dataPoint.label, e.dataPoint.y);
            }
        }]
    }

    const optionMembers = {
        animationEnabled: true,
        title:{
            text: "Organization Members",
            fontFamily: "Oswald, sans-serif",
            fontColor: "#8d7b54"
        },
        colorSet: 'members',
        backgroundColor: "transparent",
        subtitles: [{
            text: `Total: ${organizationInfo?.members?.total}`,
            verticalAlign: "center",
            fontSize: 20,
            fontColor: "#70ae9a",
            dockInsidePlotArea: true
        }],
        legend: {
            fontColor: "#8d7b54",
        },
        data: [{
            type: "doughnut",
            showInLegend: true,
            indexLabel: "{name}: {y}",
            indexLabelFontColor: "#8d7b54",
            dataPoints: [
                { name: "Admins", y: organizationInfo?.members?.admins },
                { name: "Developers", y: organizationInfo?.members?.developers },
                { name: "Members", y: organizationInfo?.members?.members },
                { name: "Guests", y: organizationInfo?.members?.guest },
            ]
    }]}

    const dataPointsForSitesPerYear = sitesPerYear ? Object.keys(sitesPerYear).map(year => ({
        y: sitesPerYear[year],
        label: year
    })) : [];

    const optionsSitesPerYear = {
        animationEnabled: true,
        theme: "light2",
        backgroundColor: "transparent",
        title:{
            text: "New Wordpress Sites",
            fontFamily: "Oswald, sans-serif",
            fontColor: "#8d7b54",
        },
        colorSet: 'wordpress',
        axisX: {
            title: "Current Year",
            reversed: true,
            labelFontColor: "#70ae9a",
        },
        axisY: {
            title: "Number of wordpress sites",
            includeZero: true,
            valueFormatString: "0",
            gridColor: "#70ae9a",
            labelFontColor: "#8d7b54",
        },
        data: [{
            type: "bar",
            dataPoints: dataPointsForSitesPerYear
        }]
    }

    const getOrgImage = () => {
        if (organizationInfo?.organization?.image) {
            return `${BASE_URL}${organizationInfo?.organization?.image}`
        }
        return null
    }

    return (
        <section className="">
            <OrganizationMenu tab={1} />
            <div>
                <div className='flex mt-8 gap-4'>
                    <div className="w-1/3  flex flex-col border border-gray-600 rounded-md justify-center items-center p-4 gap-y-2 hover:border-guinea">
                        <div className='w-24 h-24'><img src={getOrgImage() ?? organization} alt="org-image"/></div>
                        <div className='text-center text-3xl text-guinea'>{organizationInfo?.organization?.name}</div>
                        <div className='text center text-water'><span className='text-guinea'>Owner:</span> {organizationInfo?.organization?.owner}</div>
                        <div className='text center text-water'><span className='text-guinea'>Organization website:</span> {organizationInfo?.organization?.website !== '' ? organizationInfo?.organization?.website : 'Not Provided'}</div>
                    </div>
                    <div className="w-1/3 flex flex-col border border-gray-600 rounded-md justify-center items-center p-4 hover:border-guinea">
                        <CanvasJSChart options={optionsSites}/>
                        <div className='text-guinea'>Total Wordpress Sites: {organizationInfo?.sites?.total}</div>
                    </div>
                    <div className="w-1/3 flex flex-col border border-gray-600 rounded-md justify-center items-center p-4 hover:border-guinea">
                        <CanvasJSChart options={optionMembers}/>
                    </div>
                </div>
                <div className='border border-gray-600 rounded-md p-4 hover:border-guinea mt-4'>
                    <CanvasJSChart options={optionsSitesPerYear}/>
                </div>
            </div>
        </section>
    );
};

export default Dashboard;
