import React from 'react';
import overview from '../static/images/toolbar/overview.svg'
import sites from '../static/images/toolbar/sites.svg'
import tools from '../static/images/toolbar/tools.svg'
import repo from '../static/images/toolbar/repo.svg'
import {Link, useParams} from "react-router-dom";
const LeftToolBar = () => {
    const params = useParams()

    return (
        <div className="bg-eclipse fixed h-[calc(100vh-theme('spacing.nav'))] w-toolbar flex flex-col pt-8">
            <Link to={`/organization/${params?.orgId}/dashboard`}>
                <div className='flex flex-col items-center py-2 hover:bg-vulcan border-t border-t-gray-600 hover:text-guinea transition duration-100'>
                    <img src={overview} alt="overview" className='w-10 h-10'/>
                    <span className='text-toolbar-size font-istok mt-1 text-ultimate'>Overview</span>
                </div>
            </Link>

            <Link to={`/organization/${params?.orgId}/sites`}>
                <div className='flex flex-col items-center py-2 hover:bg-vulcan border-t border-t-gray-600 hover:text-guinea transition duration-100'>
                    <img src={sites} alt="overview" className='w-10 h-10'/>
                    <span className='text-toolbar-size font-istok mt-1 text-ultimate'>Sites</span>
                </div>
            </Link>

            <Link to={`/organization/${params?.orgId}/tools`}>
                <div className='flex flex-col items-center py-2 hover:bg-vulcan border-t border-t-gray-600 hover:text-guinea transition duration-100'>
                    <img src={tools} alt="overview" className='w-10 h-10'/>
                    <span className='text-toolbar-size font-istok mt-1 text-ultimate'>Tools</span>
                </div>
            </Link>

            <Link to={`/organization/${params?.orgId}/repository/upload-file`}>
                <div className='flex flex-col items-center py-2 hover:bg-vulcan border-t border-t-gray-600 hover:text-guinea transition duration-100'>
                    <img src={repo} alt="overview" className='w-10 h-10'/>
                    <span className='text-toolbar-size font-istok mt-1 text-ultimate'>Repository</span>
                </div>
            </Link>
        </div>
    );
};

export default LeftToolBar;