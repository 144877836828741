import React from 'react'
import LeftToolBar from "./LeftToolBar";
import NavBar from "./NavBar";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";


const AppContainer = () => {

    return (
        <div className='bg-cinder'>
            <NavBar/>
            <LeftToolBar/>
            <section className="bg-cinder ml-toolbar mt-nav min-h-[calc(100vh-theme('spacing.nav'))] px-8 py-8">
                <Outlet/>
            </section>
        </div>
    )
}

export default AppContainer