import {useEffect, useState} from "react";
import {toast} from "react-toastify";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import {API_SITES_VULNERABLE} from "../api/endpoints";
import {useParams} from "react-router-dom";
import SiteVulnerableCard from "../components/SiteVulnerableCard";

const SitesVulnerable = () => {
    const [sites, setSites] = useState([]);
    const axios = useAxiosPrivate()
    const params = useParams()

    const fetchVulnerableSites = async () => {
        try {
            const response = await axios.get(API_SITES_VULNERABLE(params?.orgId));
            setSites(response?.data)
        }catch(err) {
            const error_data = err?.response?.data?.errors?.[0] || null;
            if (error_data) {
                toast.error(error_data?.detail);
            } else {
                toast.error("Fetch vulnerable sites failed failed");
            }
        }
    }

    useEffect(() => {
        fetchVulnerableSites()
    }, [])

    return (
        <div className='flex flex-col gap-y-6'>
            {sites && sites.map((site) => (
                <SiteVulnerableCard key={site.id} data={site} />
            ))}
        </div>
    );
};

export default SitesVulnerable;