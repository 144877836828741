import axios, { axiosPrivate } from "../api/axios";
import { useEffect } from "react";
import useAuthService from "./useAuthService";
import { API_AUTH_REFRESH_TOKEN } from "../api/endpoints";

const useAxiosPrivate = () => {
    const authService = useAuthService();
    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
        failedQueue.forEach(prom => {
            if (error) {
                prom.reject(error);
            } else {
                prom.resolve(token);
            }
        });

        failedQueue = [];
    };

    useEffect(() => {
        const requestInterceptor = axiosPrivate.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${authService?.getAccessToken()}`;
                }
                return config;
            },
            error => Promise.reject(error)
        );

        const responseInterceptor = axiosPrivate.interceptors.response.use(
            response => response,
            async (error) => {
                const originalRequest = error?.config;
                if (error?.response?.status === 401 && !originalRequest._retry) {
                    if (isRefreshing) {
                        return new Promise((resolve, reject) => {
                            failedQueue.push({ resolve, reject });
                        }).then(token => {
                            originalRequest.headers['Authorization'] = `Bearer ${token}`;
                            return axiosPrivate(originalRequest);
                        }).catch(err => {
                            return Promise.reject(err);
                        });
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    try {
                        const response = await axios.post(API_AUTH_REFRESH_TOKEN, {
                            refresh: authService?.getRefreshToken(),
                        });

                        const newAccessToken = response?.data?.access;
                        authService?.setAccessToken(newAccessToken);
                        axiosPrivate.defaults.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        originalRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;

                        processQueue(null, newAccessToken);
                        return axiosPrivate(originalRequest);
                    } catch (error) {
                        processQueue(error, null);
                        return Promise.reject(error);
                    } finally {
                        isRefreshing = false;
                    }
                }

                return Promise.reject(error);
            }
        );

        // Cleanup on component unmount
        return () => {
            axiosPrivate.interceptors.request.eject(requestInterceptor);
            axiosPrivate.interceptors.response.eject(responseInterceptor);
        };
    }, [authService]);

    return axiosPrivate;
};

export default useAxiosPrivate;
