import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";

const Organization = () => {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/organization/${params?.orgId}/dashboard`);
    }, [params])

    return (
        <></>
    );
};

export default Organization;