import React, { createContext, useState } from 'react';

export const AppContext = createContext(undefined);

export const AppProvider = ({ children }) => {
    const [navbarData, setNavbarData] = useState(null);
    const [toolbarData, setToolbarData] = useState(null);
    const [userData, setUserData] = useState(null);

    return (
        <AppContext.Provider value={
            {
                navbarData, setNavbarData,
                toolbarData, setToolbarData,
                userData, setUserData
            }
        }>
            {children}
        </AppContext.Provider>
    );
};