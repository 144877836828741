import React, {useContext, useEffect, useState} from 'react';
import { BsDatabaseCheck, BsDatabaseExclamation, BsFiletypeDoc, BsDisplay} from "react-icons/bs";
import { LiaSyncSolid } from "react-icons/lia";
import { TbPlugConnectedX } from "react-icons/tb";
import { FaWordpress } from "react-icons/fa6";
import { GrTestDesktop } from "react-icons/gr";
import { LuExternalLink } from "react-icons/lu";
import { GrHostMaintenance } from "react-icons/gr";
import cardPlaceholder from "../static/images/sites/site-card-placeholder.png";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";
import {AppContext} from "./AppContext";
import {API_SITES_SYNC_SITE} from "../api/endpoints";
import useAxiosPrivate from "../hooks/useAxiosPrivate";

const SiteCard = ({data: initialData}) => {
    const [data, setData] = useState(initialData);
    const {id, name, url, type, is_connected, backups, reports, image, plugins, env_status} = data
    const navigate = useNavigate();
    const params = useParams();
    const axios = useAxiosPrivate()
    const { setNavbarData } = useContext(AppContext);
    const [isVulnerable, setVulnerable] = useState(false)
    const [maintenanceMode, setMaintenanceMode] = useState(false)

    useEffect(() => {
        let vulnerability = false
        plugins.forEach((plugin) => {
           const plugin_vulnerabilities = plugin?.vulnerabilities
            if (plugin_vulnerabilities) {
                if(plugin_vulnerabilities.some(vulnerability =>vulnerability.severity === 'Critical' || vulnerability.severity === 'High')) {
                    vulnerability = true
                    return
                }
            }
        })
        setVulnerable(vulnerability)
    }, [plugins])

    useEffect(() => {
        const mode = env_status
        if (mode?.includes('update') || mode?.includes('development')) {
            setMaintenanceMode(true);
        } else {
            setMaintenanceMode(false);
        }

        if (!is_connected) {
            setMaintenanceMode(false)
        }
    }, [env_status, is_connected])

    const syncSiteData = async () => {
        const toast_id = toast.loading("Sync site please wait...")
        try{
            const response = await axios.post(API_SITES_SYNC_SITE(params?.orgId, id))
            setNavbarData({name: response?.data?.name})
            setData(response?.data)
            toast.update(toast_id, {render: 'Request send, If site is not connected try to disable and enable Grand Digital Connect plugin', type: "success", isLoading: false, autoClose: 2000})
        }catch(err){
            const error_data = err?.response?.data?.errors?.[0] || null
            if (error_data) {
                toast.update(toast_id, {render: error_data.detail, type: "error", isLoading: false, autoClose: 2000})
            }else {
                toast.update(toast_id, {render: "Sync site failed", type: "error", isLoading: false, autoClose: 2000})
            }
        }
    }

    const syncHandler = () => {
        syncSiteData()
    }

    const reconnectHandler = () => {
        syncSiteData()
    }

    const cardClickHandler = () => {
        navigate(`/organization/${params?.orgId}/site/${id}`)
    }

    const backClickHandler = () => {
        setNavbarData({name: `${name}`})
        navigate(`/organization/${params?.orgId}/site/${id}/backups`)
    }

    const reportsClickHandler = () => {
        setNavbarData({name: `${name}`})
        navigate(`/organization/${params?.orgId}/site/${id}/reports`)
    }

    const pluginsClickHandler = () => {
        setNavbarData({name: `${name}`})
        navigate(`/organization/${params?.orgId}/site/${id}/plugins`)
    }

    return (
        <div className={`w-[290px] bg-vulcan shadow-eclipse-dark shadow-lg rounded-lg overflow-hidden transition duration-200 border border-gray-600 hover:border-guinea`}>
            <div className=''>
                <div onClick={cardClickHandler} className="bg-cinder p-4 cursor-pointer hover:bg-cinder-light">
                    <h2 className={`text-guinea text-xl font-oswald font-light`}>{name}</h2>
                    <div className={`text-${type === "live" ? "water":"guinea"} text-sm justify-end mt-2 font-poppins flex items-center gap-x-2`}><span>{type === 'live' ? <BsDisplay/> : <GrTestDesktop/>}</span> {type}</div>
                </div>
                <div className="p-4 bg-eclipse">
                    <div className='h-[180px] relative overflow-hidden'>
                        <img onClick={cardClickHandler} src={image ? image : cardPlaceholder} alt={name}
                             className='rounded-sm cursor-pointer absolute w-full h-full'/>
                    </div>
                    <div className="flex items-center text-ultimate font-poppins mt-4 text-sm gap-x-2">
                    <div>
                        <LuExternalLink className='w-4 h-4 text-guinea'/>
                    </div>
                        <div>
                            <a href={url} target="_blank" className="text-water text-sm hover:text-water-light line-clamp-1">{url}</a>
                        </div>
                    </div>
                    <div className='flex gap-x-4 mt-6'>
                        <div onClick={backClickHandler}    data-tip={'Backup'} className='tooltip tooltip-top text-3xl cursor-pointer' >{backups.length ? <BsDatabaseCheck className='text-water hover:text-water-light'/> : <BsDatabaseExclamation className='text-spot hover:text-redom'/>}</div>
                        <div onClick={reportsClickHandler} data-tip={'Google Report'} className={`tooltip tooltip-top text-3xl cursor-pointer text-${reports.length ? "water":"spot"} hover:${reports.length ? "text-water-light":"text-redom"}`}><BsFiletypeDoc/></div>
                        <div onClick={pluginsClickHandler} data-tip={'Vulnerable'} className={`tooltip tooltip-top text-3xl cursor-pointer text-${isVulnerable ? "spot":"water"} hover:${isVulnerable ? "text-spot-light":"text-water-light"}`}><FaWordpress /></div>
                        <div onClick={cardClickHandler}    data-tip={'Update Mode'} className={`tooltip tooltip-top text-3xl cursor-pointer text-${maintenanceMode ? "spot":"water"} hover:${maintenanceMode ? "text-spot-light":"text-water-light"}`}><GrHostMaintenance /></div>
                    </div>
                </div>
            </div>

            <div className="bg-vulcan p-4 text-center">
                {is_connected ?
                    <button onClick={syncHandler} className="bg-water flex justify-center py-1 items-center gap-x-2 text-[#1a1a1a] text-sm rounded w-full hover:bg-water-light font-istok"><span><LiaSyncSolid/></span><span>Sync Site</span></button> :
                    <button onClick={reconnectHandler} className="bg-spot flex justify-center py-1 items-center gap-x-2 text-milk text-sm rounded w-full hover:bg-spot-light font-istok"><span><TbPlugConnectedX/></span><span>Reconnect</span></button>
                }
            </div>
        </div>
    );
};

export default SiteCard;